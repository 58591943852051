<div class="uc-inventory-grid" fxLayout="column">
  <mat-card *ngIf="regionDisplay" fxLayout="row">
    <label style="margin:10px">UPLOAD EXCEL</label>&nbsp;&nbsp;
    <input style="padding-top:0.5rem" type="file" #inputFile (change)="onFileChange($event)" multiple="false" [disabled]="sharedDataService.readonlyToyota"/>
  </mat-card>
  <mat-card fxLayout="row" fxFlex="100">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="80" style="padding: 10px">
      <div fxFlex="100" fxLayoutAlign="space-around center">
        <label fxFlex="5">Month</label>
        <mat-select fxFlex="30" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:50%;"
          class="mat-no-underline" name="month" [(ngModel)]="selectedMonth" #month="ngModel">
          <mat-option [value]="">
            -- select --
          </mat-option>
          <mat-option *ngFor="let i of monthList" [value]="i.value">{{i.name}}</mat-option>
        </mat-select>

        <label fxFlex="5">Year</label>
        <mat-select fxFlex="30" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:50%;"
          class="mat-no-underline" name="year" [(ngModel)]="selectedYear" #year="ngModel">
          <mat-option [value]="">
            -- select --
          </mat-option>
          <mat-option *ngFor="let i of yearList" [value]="i">{{i}}</mat-option>
        </mat-select>

        <label fxFlex="5">Region</label>
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
          <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
            class="mat-no-underline" name="region" [(ngModel)]="regionSelection" #region="ngModel">
            <mat-option [value]="">
              -- select --
            </mat-option>
            <mat-option *ngFor="let i of regionItems" [value]="i.code">{{i.name}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;">
      <button (click)="fetchObjective();" mat-button mat-raised-button>Apply</button>
    </div>
  </mat-card>
  <div fxLayout="row" fxFlex="100">
    <mat-card fxLayout="column" fxFlex="65">
      <ngx-datatable #inventoryTable class='material striped dd-grid-size' [rows]='rows' [columnMode]="'force'"
        [headerHeight]="65" [footerHeight]="0" [rowHeight]="40" [scrollbarV]="true" [scrollbarH]="true"
        [externalSorting]="true">
        <ngx-datatable-column *ngFor="let columnDef of columnDefs" [name]="columnDef.key" [prop]="columnDef.fieldName"
          [width]="columnDef.columnWidth" [minWidth]="columnDef.columnMinWidth" [maxWidth]="columnDef.columnMaxWidth"
          [flexGrow]="columnDef.columnWidth">
          <ng-template let-model="model" ngx-datatable-header-template>
            <div class="datatable-header-cell-label">
              <div fxLayout="column" class="dd-grid-label-align">
                <div fxFlex="100" class="cursorP"
                  [class.dd-grid-sorting]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)===''"
                  [class.dd-grid-sorting-asc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList) === 'ASC'"
                  [class.dd-grid-sorting-desc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)==='DESC'"
                  [innerHTML]="columnDef.key" (click)="columnDef.sortable !== 'true' || applySort(columnDef.fieldName)">
                </div>
                <div fxFlex>
                  <mat-form-field class="dd-inv-input-background" style="width:100%">
                    <!--<mat-form-field>-->
                    <input matInput [(ngModel)]="columnDef.filterValue" *ngIf="columnDef.key !== 'VIN'"
                      [ddFieldValidator]="columnDef.dataTypeformat" [disabled]="sharedDataService.readonlyToyota"
                      (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                    <input matInput [(ngModel)]="columnDef.filterValue" style="text-transform: uppercase;"
                      *ngIf="columnDef.key === 'VIN'" [ddFieldValidator]="columnDef.dataTypeformat" 
                      [disabled]="sharedDataService.readonlyToyota"
                      (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                    <!--</mat-form-field>-->
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <ng-template [ngIf]="columnDef.key === 'OBJECTIVE'" [ngIfElse]="defaultTxt">
              <input *ngIf="row.dealerName !== null && !row.dealerName.includes('Total District'); else defaultTxt" style="width:100%"
              [ddFieldValidator]="validationTypes.NUMBER_WITH_DECIMAL_2" [disabled]="sharedDataService.readonlyToyota ||
              row.terminatedFlag !== 'N'"
              (keyup)="updateValue($event, 'objective', rowIndex, row)" type="text" [value]="value">
            </ng-template>
            <ng-template #defaultTxt>{{value}}</ng-template>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>
    <div fxLayout="column" fxFlex="2">
    </div>
    <mat-card *ngIf="regionDisplay" fxLayout="column" fxFlex="30">
      <table style="margin-top:30%" mat-table aria-describedby="Cumulative Objective Count Table">
        <th></th>
        <tr>
          <td style="width: 70%">{{regionDisplay}} (Regional)</td>
          <td style="width: 30%">{{objectiveCountRegional}}</td>
        </tr>
        <tr>
          <td style="width: 70%">{{regionDisplay}} (Dealer)</td>
          <td style="width: 30%">{{objectiveCountCurrent}}</td>
        </tr>
      </table>
    </mat-card>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container" style="margin: 0.6% 0 0.6% 0;width:100%">
  <div style="padding-right: 5px;">
    <button [disabled]="(!regionDisplay || sharedDataService.readonlyToyota)" mat-button mat-raised-button class="dd-desription-save-button" type="submit"
      (click)="saveDealerObjective()">SAVE</button>
  </div>
</div>
