import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import * as messageActions from '../../../actions/message-data.action';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';
import * as fromRoot from '../../../reducers';
import { ExportToExcelService } from '../../../services/common/excel-service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import * as models from '../../../services/model/models';
import { UCInventorySummaryView } from '../../../services/view-model/inventory-summary-view';
import * as viewModels from '../../../services/view-model/view-models';
import { ViewConstants } from '../../../shared/shared.constants';
import { InventorySummaryGridColumns } from './json/UCReportGridColumns';
import { DatePipe } from '@angular/common';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment'
import * as alasql from 'alasql';



@Component({
  selector: 'uc-dealer-wise-sales-cmp-report-page',
  templateUrl: 'dealer-wise-sales-cmp-report-page.component.html'
})

export class UCDealerWiseSalesCMPPageReportComponent implements OnInit, OnDestroy {
  // Observable to save inventory screen data
  inventorySummaryData$: Observable<UCInventorySummaryView>;
  // Subscriber for Inventory Data
  inventorySummarySubscriber: any;
  // Array variable used to store columns
  dealerTotal: any;
  public columnDefs: any[] = [];
  public columnDefsTotal: any[] = [];
  dealerCodes: Array<any> = [];
  // Array variable to keep the original data for filter purpose
  originalData: Array<models.UCInventorySummary>;
  // filterOriginalData: Array<models.UCInventorySummary>;
  // Array variable to keep the filtered data for filter purpose
  filteredData: Array<models.UCInventorySummary>;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];
  // Boolean to reveal whether the dealer code is selected or not
  isInventorySelected: boolean = false;
  // certificationStatus
  selectedCertificationStatus: string = '';
  // makeCode
  selectedMakeCode: string = '';
  // To store seleted vehicle index
  public selectedVehicleIndex: number = 0;
  // Boolean variable to show/hide the clear all button
  showClearAllButton: boolean;
  // Variable to display the inventories count
  totalInventoriesCount: any;
  // Dealer code entered in search toolbar
  enteredDealerCode: string;
  // vin entered in search toolbar
  enteredvin: string;
  // To hold the last sort event
  currentSortEvent: any;
  reportType: string;
  fromDate: string;
  toDate: string;
  // to clear the current sorting for all columns
  clearSort: boolean = false;
  totalSaleCount: number;
  totalGridSaleCount: number;
  reportStartDate: string;
  reportEndDate: string;
  district: string;
  region: string;
  regionSelection: string;
  districtSelection: string;
  // Observable to obtain VehicleDetailView object
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;
  // Observable to obtain RdrDetailView object
  rdrDetails$: Observable<viewModels.RdrDetailView>;
  // Private array variable to display the rows in grid
  private _rows: Array<models.UCInventorySummary>;
  // Property to set and get the rows
  get rows(): Array<models.UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<models.UCInventorySummary>) {
    if (value.length === 1 && value[0].vin === '') {
      this.totalInventoriesCount = 0;
    } else {
      if (!isNil(this.totalSaleCount)) {
        this.totalInventoriesCount =
          value.length + ' ( ' + this.totalSaleCount + ' )';
      } else {
        this.totalInventoriesCount = value.length;
      }
    }
    this._rows = value;
  }
  // private moment = require('moment');
  // selected Disclaimer
  private row: models.UCInventorySummary = {};
   keysArray = [
    "acqToSaleAvgMTD",
    "acqToSaleAvgYTD",
    "certToSaleAvgMTD",
    "certToSaleAvgYTD",
    "dailyGoldSales",
    "dailySilverSales",
    "daySupplyFullInv",
    "daySupplyInvLessThan60",
    "dealerCd",
    "districtCode",
    "invLessThan60Days",
    "monthlyGoldSales",
    "monthlySilverSales",
    "yearlyGoldSales",
    "yearlySilverSales",
    "onHandfullInv",
    "prevYrSameDaySales",
    "prevYrSameMonthSales",
    "prevYrTotalSales",
    "previousMonthSameDayOnHandFullInv",
    "previousMonthSameDayOnHandLessThan60Days",
    "previousYrSameDayOnHandFullInv",
    "previousYrSameDayOnHandLessThan60Days",
    "regionCode", 
    "turnRateFullInv", 
    "turnRateInvLessThan60",
    "dailyGoldPlusSilverSales",
    "monthlyGoldPlusSilverSales",
    "totalYearlySilverGoldSales"];
  constructor(private store: Store<fromRoot.AppState>,
    public sharedFuncService: SharedFunctionService,
    public sharedDataService: SharedDataService,
    public router: Router,
    private viewContainerRef: ViewContainerRef,
    private datepipe: DatePipe,
    private exportToExcelService: ExportToExcelService) {
    this.inventorySummaryData$ = store.select(fromRoot.getVehicleReport);
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.sharedDataService.paginationCount = 1;
    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
      this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
    this.districtSelection = 'ALL';
    this.onLoad('m');
    this.dealerTotal = [];
    this.totalSaleCount = 0;
    this.totalGridSaleCount = 0;
     }
  /**
   * onLoad method
   */
  onLoad(event: any) {
    this.reportType = event;
    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
      this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
    this.districtSelection = 'ALL';
    this.dealerTotal = [];
    this.totalSaleCount = 0;
    this.totalGridSaleCount = 0;
    if (!isNil(this.sharedDataService.dealerData)) {
      this.sharedDataService.dealerData.map(e => {
        this.dealerCodes.push(e.dealerCode);
      });
    }
    let date = new Date();
    let dd = date.getDate().toString();
    let mm = (date.getMonth() + 1).toString();
    let yyyy = date.getFullYear();
    if (dd < '10') {
      dd = '0' + dd.toString;
    }
    if (mm < '10') {
      mm = '0' + mm;
    }
    if (this.reportType === 'd') {
      this.fromDate = mm + '/' + dd + '/' + yyyy;
      this.toDate = mm + '/' + dd + '/' + yyyy;
    } else if (this.reportType === 'm') {
      this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
      this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
    } else if (this.reportType === 'y') {
      this.fromDate = '01' + '/' + '01' + '/' + yyyy;
      this.toDate = '12' + '/' + '31' + '/' + yyyy;
    }
    this.sharedFuncService.setCurrentScreenName(
      ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    // this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
    this.columnDefs = InventorySummaryGridColumns.gridColumns;
    // this.columnDefsTotal = InventorySummaryGridColumns.totalGridColumns;
    this.sharedDataService.setSelectedVehicle('');
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    // let report = {
    //   reportMode: '37',
    //   // fromDate: null,
    //   // inputDate: new Date().toISOString(),
    //   inputDate: moment().format('MM/DD/YYYY'),
    //   toDate: null,
    //   // from: 0,
    //   makeCode: this.sharedDataService.brandName,
    //   regionCode: this.regionSelection,
    //   districtNo: this.districtSelection,
    //   dealerCodes: this.dealerCodes
    // };
    // this.store.dispatch(
    //   new vehicleReportActions.GetInventoryReport(report));
    // this.sharedDataService.paginationCount = 1;
    // this.sharedDataService.isNextVisible = false;
  }

  setCertificationDate(data: any) {
    const datepipe = new DatePipe('en-US');
    data.forEach(element => {
      if (element.certification) {
        const certificationDate = element.certification.certificationDate;
        if (isNil(certificationDate)) {
          element.certification.certificationDate = '';
        } else {
          // Convert certificationDate to Date object if necessary
          const dateObj = certificationDate instanceof Date ? certificationDate :
            new Date(certificationDate);
          // Check if the date is valid
          if (isNaN(dateObj.getTime())) {
            element.certification.certificationDate = '';
          } else {
            // Convert UTC to CST/CDT using toLocaleString with timeZone
            const cstDateStr = dateObj.toLocaleString('en-US', { timeZone: 'America/Chicago' });
            // Extract date components
            const cstDate = new Date(cstDateStr);
            // Format the CST date to 'MM-dd-yyyy'
            element.certification.certificationDate = datepipe.transform(cstDate, 'MM-dd-yyyy');
          }
        }
      }
    });

    return data;
  }

  triggerReportLoadOnAction(){
    this.inventorySummarySubscriber = this.inventorySummaryData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        this.enteredDealerCode = '';
        this.enteredDealerCode = (<any>Object).assign(this.enteredDealerCode, '');
        this.enteredvin = '';
        this.enteredvin = (<any>Object).assign(this.enteredvin, '');
        this.isInventorySelected = false;
        this.selectedCertificationStatus = '';
        this.selectedMakeCode = '';
        if (!isNil(data.message)) {
          this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
            data.message
          ));
        } else {
          if (data.inventories.length > 0) {
            // let copyData = data.inventories;

            let copyData = this.setDataForTotalGridAndAll(data.inventories);
            // let copyData = this.totalCount(data.inventories);

            this.reportStartDate = data.startDate;
            this.reportEndDate = data.endDate;
            // this.totalSaleCount = data.count || this.removeDupForCount(data.inventories);
            // this.totalSaleCount = data.inventories?.length;
            if (!isNil(this.totalSaleCount)) {
              this.totalInventoriesCount =
                this.totalInventoriesCount + ' ( ' + this.totalSaleCount + ' )';
            }
            if (data.inventories.length !== 5000) {
              this.sharedDataService.isNextVisible = false;
            } else {
              this.sharedDataService.isNextVisible = true;
            }
            if (!isNil(this.sharedDataService.dealerData)) {
              copyData.forEach(element => {
                if (this.sharedDataService.dealerData
                  .filter(t => t.dealerCode === element.dealerCd).length > 0) {
                  element.dealerName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].dealerName;
                  element.areaRegionNo = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].areaRegionNo;
                  element.regionName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode
                      === element.dealerCd)[0].areaRegionName;
                  element.districtNo = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode
                      === element.dealerCd)[0].districtNo;
                } else {
                  element.dealerName = '';
                  element.areaRegionNo = '';
                  // element.regionName = element?.regionCode == 'TOTAL' ? 'TOTAL' : '';
                  element.regionName = ['TOTAL', 'AVERAGE']?.includes(element.regionCode) ? element.regionCode  : '';
                  element.districtNo = '';
                }
              });
            }
            this.rows = cloneDeep(sortBy(copyData, 'VIN'));
            // this.dealerTotal = this.generateTotalGridData(copyData);
            this.originalData = cloneDeep(copyData);
            this.filteredData = cloneDeep(this.originalData);
            // this.filterOriginalData = cloneDeep(copyData);
            // let message: models.Message = {};
            // message.message = 'By default current month filter applied in the report grid';
            // message.type = models.MessageType.WARNING;
            // this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
          } else {
            this.rows = cloneDeep([]);
          }
        }
      }
    });
  }

  reportLoad(event: any) {
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    this.reportType = 'm';
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.regionSelection = event.region;
    this.districtSelection = event.district;
    this.dealerCodes = [];
    let distFilter = 0;
    this.dealerTotal = [];
    this.totalSaleCount = 0;
    this.totalGridSaleCount = 0;
    let regFilter = 0;
    let indexNum = 0;
    let distNo;
    let regName;
    this.dealerCodes = [];

    if ((this.regionSelection === 'ALL' || this.regionSelection === undefined))
      regFilter = 0;
    else regFilter = 1;
    if (this.districtSelection === 'ALL' || this.districtSelection === undefined)
      distFilter = 0;
    else distFilter = 1;

    if (!isNil(this.sharedDataService.dealerData)) {
      this.sharedDataService.dealerData.map(e => {
        if (regFilter === 0 && distFilter === 0) {
          this.dealerCodes.push(e.dealerCode);
        } else if (regFilter === 1 && distFilter === 0) {
          if (e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        } else if ((regFilter === 0 && distFilter === 1)) {
          if (e.districtNo === this.districtSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        } else {
          if (e.districtNo === this.districtSelection
            && e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        }
      });
    }

    let report = {
      reportMode: '38',
      // fromDate: event.fromDate,
      inputDate: event.fromDate,
      toDate: event?.toDate || null,
      makeCode: this.sharedDataService.brandName,
      from: 0,
      regionCode: event.region,
      districtNo: event.district,
      dealerCodes: this.dealerCodes
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
    this.sharedDataService.paginationCount = 1;
    this.sharedDataService.isNextVisible = false;

    this.triggerReportLoadOnAction();
  }

  refreshFilters() {
    this.showClearAllButton = false;
    if (!isNil(this.currentSortEvent)) {
      this.applyInventorySort(this.currentSortEvent);
    }
    this.clearSort = false;
    InventorySummaryGridColumns.gridColumns.forEach(column => {
      column.filterValue = '';
    });
  }

  applyInventorySort(event: any) {
    this.currentSortEvent = event;
    this.rows =
      cloneDeep(this.sharedFuncService.applySort(this.rows, event));
  }

  countIncreaseEvent(event: any) {
    let requestArray: Array<any> = [];
    while (Number(this.sharedDataService.paginationCount) < this.totalSaleCount) {
      let report = {
        reportMode: '38',
        // fromDate: this.fromDate,
        inputDate: this.fromDate,
        toDate: this.toDate || null,
        makeCode: this.sharedDataService.brandName,
        from: this.sharedDataService.paginationCount - 1,
        dealerCodes: this.dealerCodes
      };
      requestArray.push(report);
      this.sharedDataService.paginationCount += 5000;
    }

    // requestArray.splice(requestArray.length - 1);

    if (requestArray.length > 1) {
      this.store.dispatch(
        new vehicleReportActions.GetInventoryReport(requestArray));
    }

  }

  countDecreaseEvent(event: any) {
    this.sharedDataService.paginationCount -= 5000;
    // let saledata: any = {};
    // saledata.dealerCd = this.sharedDataService.dealerCode;
    // saledata.paginationCount = this.sharedDataService.paginationCount - 1;
    // saledata.type = 'InitialLoad';
    let report = {
      reportMode: '38',
      // fromDate: this.fromDate,
      inputDate: this.fromDate,
      toDate: this.toDate || null,
      makeCode: this.sharedDataService.brandName,
      from: this.sharedDataService.paginationCount - 1,
      dealerCodes: this.dealerCodes
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  ApplyFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.filterType === filter.filterType
          && data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      this.filteredData = cloneDeep(this.originalData);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          if (arrayElement.filterType === models.FilterType.CONTAINS) {
            this.applyContainsFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
            this.applyStartsWithFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.SPACES) {
            this.applySpacesFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
            this.applyPriceFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
            this.applyDateFilter(arrayElement);
          }
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllButton = true;
      } else {
        this.showClearAllButton = false;
      }
      // if (this.filteredData.length === 0) {
      //     let test: models.UCInventorySummary = {};
      //     test.vin = '';
      //     this.filteredData.push(test);
      // }
      this.totalSaleCount = this.filteredData.length;
      this.rows = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
      return !isNil(propertyValue) && propertyValue.toLowerCase().indexOf(filterValue) > -1;
    });
  }

  applyStartsWithFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = this.accessProperties(inventory, filterElement.columnname);
      return !isNil(propertyValue) &&
        String(propertyValue).toLowerCase()
          .startsWith(filterValue);
    });
  }

  applySpacesFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      return !isNil(inventory.accessoriesDetails)
        && inventory.accessoriesDetails !== undefined
        && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
    });
  }

  accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
    let filterValues = String(value).split(' ');
    let returnValue = false;
    let positiveCount = 0;
    if (!isNil(accessories) && accessories.length > 0) {
      filterValues.forEach(filter => {
        if (accessories.filter(acc =>
          acc.accessssoriesCode.trim().toLowerCase() === filter ||
          acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
          positiveCount++;
        }
      });
    }
    if (positiveCount === filterValues.length) {
      return true;
    }
    return false;
  }

  applyPriceFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyDateFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    if (fullFilterValue.length > 1) {
      let firstLetter = fullFilterValue.substr(0, 1);
      let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
      this.filteredData = this.filteredData.filter(inventory => {
        let propValue = this.accessProperties(inventory, filterElement.columnname);
        if (!isNil(propValue)) {
          if (firstLetter === '<') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
            }
          } else if (firstLetter === '>') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
            }

          } else if (firstLetter === '=') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }

          } else {
            filterVal = fullFilterValue.
              substr(0, fullFilterValue.length);
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }
          }
        }
        return true;
      });
    }
  }

  accessProperties(object: any, str: string) {
    let propertiesString = str.split('.');
    let i = 0;
    propertiesString.forEach(val => {
      object = object[propertiesString[i]];
      i++;
    });
    return object;
  }

  clearAllFilters() {
    this.rows = cloneDeep(this.originalData);
    this.filteredData = cloneDeep(this.originalData);
    this.filterArray = [];
    this.refreshFilters();
  }

  getFormattedTime() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + '' + m + '' + d + '' + h + '' + mi + '' + s;
  }

  getFormattedDateForDisplay() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    return m + '/' + d + '/' + y;
  }

  getDateRange(type: string): string {
    let date = new Date();
    if (type === 'm') {
      return this.sharedFuncService.convertDateDisplay(this.reportStartDate)
        + ' THRU ' + this.sharedFuncService.convertDateDisplay(this.reportEndDate);
    } else if (type === 'y') {
      return '01/01/' + date.getFullYear() + ' THRU ' + '12/31/' + date.getFullYear();
    } else if (type === 'd') {
      return this.convertDateDisplay(date) + ' THRU ' + this.convertDateDisplay(date);
    } else if (type === 'i') {
      return this.convertDateDisplay(this.fromDate) + ' THRU ' +
        this.convertDateDisplay(this.toDate);
    }
  }

  convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return month + '/' + dt + '/' + year;
  }

  // To print the Inventory grid data
  downLoadPdf() {
    setTimeout(() => {
      this.sharedDataService.showLoader = true;
    }, 0);
    // let doc: any = null;
    let doc: any = new jsPDF('landscape', 'mm', 'a2');;
    let fileName = 'RegionalTCUVSalesAndStockSummaryReport' + this.getFormattedTime();
    let reportHeaderText = 'Regional TCUV Sales and Stock Summary Report';
    let inventoryData = this.inventoryGridData();
    if (!isNil(inventoryData) && !isNil(inventoryData.columns)) {
      if (inventoryData.columns.length <= 5) {
        doc = new jsPDF('landscape', 'mm', 'a4');
      } else if (inventoryData.columns.length <= 10) {
        doc = new jsPDF('landscape', 'mm', 'a3');
      } else if (inventoryData.columns.length <= 15) {
        doc = new jsPDF('landscape', 'mm', 'a2');
      } else if (inventoryData.columns.length <= 20) {
        doc = new jsPDF('landscape', 'mm', 'a1');
      } else {
        doc = new jsPDF('landscape', 'mm', 'a0');
      }
    }
    if (doc !== null) {
      let totalPagesExp = '{total_pages_count_string}';
      doc.setFontSize(15);
      doc.text('Report Generated Date: ' + this.getFormattedDateForDisplay(), 500, 22);
      doc.setFontSize(20);
      doc.text(170, 15, ViewConstants.APPLICATION_TITLE_FOR_REPORT);
      doc.setFontSize(15);
      doc.text(180, 25, reportHeaderText);
      // doc.text(230, 32, this.getDateRange(this.reportType));

      let pageContent = function (data) {
        let str = 'Page ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp;
        }
        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.setFontSize(12);
        doc.text(str, 5, pageHeight - 10);
      };
      doc.autoTable(inventoryData.columns, inventoryData.rows, {
        startY: 40,
        styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
        columnStyles: { text: { columnWidth: 'auto' } },
        addPageContent: pageContent
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
      }
      doc.save(fileName + '.pdf');
    }

    setTimeout(() => {
      this.sharedDataService.showLoader = false;
    }, 0);
  }

  // To download the xlsx in inventory screen
  downLoadXlsx() {
    // let reportHeaderText = 'REGIONAL AND DISTRICT WISE SALES COMPARISON REPORT';
    let reportHeaderText = 'Regional TCUV Sales and Stock Summary Report';
    let exportData = {
      'title': reportHeaderText,
      'header': this.inventoryGridData().columns,
      'data': this.inventoryGridData().rows,
      'isTotalGrid': false,
      'generationDate': this.getFormattedDateForDisplay(),
      // 'fileName': 'RegionlAndDistrictWiseSales'
      'fileName': 'RegionalTCUVSalesAndStockSummaryReport'
    };
    
    this.exportToExcelService.exportAsExcelFile(exportData);
    this.sharedDataService.showLoader = false;

  }

  // To download the csv in inventory screen
  // downLoadCsv() {
  //   let tempData: any;
  //   let inventoryDataNew: Array<any> = [];
  //   this.rows.forEach(row => {
  //     tempData = {};
  //     InventorySummaryGridColumns.gridColumns.forEach(column => {
  //       let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
  //       tempData[column.key] = columnField;
  //     });
  //     inventoryDataNew.push(tempData);
  //   });
  //   alasql('SELECT * INTO CSV("inventorySummary.csv",{headers:true, separator:","}) FROM ?',
  //     [inventoryDataNew]);
  //   this.sharedDataService.showLoader = false;

  // }

  // To get inventory grid data
  inventoryGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    if (InventorySummaryGridColumns.gridColumns && this.rows) {
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.rows.forEach(row => {
        tempRows = [];
        InventorySummaryGridColumns.gridColumns.forEach(column => {
          let columnField =
            this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }



  ngOnDestroy() {
    if (this.inventorySummarySubscriber) {
      this.inventorySummarySubscriber.unsubscribe();
    }
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
  }

  getRegionForFilter(event: any) {
    if(event.region === 'ALL'){
      this.originalData =  this.filteredData;
    }
    else{
      const _filterData = this.filteredData?.filter((items: any) => items.regionName == event.region);
      this.originalData = this.totalCount(_filterData);
    }
    // this.originalData = event.region === 'ALL' ? this.filteredData :
    //   this.filteredData?.filter((items: any) => items.regionName == event.region);     
      
    this.rows = cloneDeep(sortBy(this.originalData, 'VIN'));
    this.totalSaleCount = this.originalData?.length < 0 ? 0 :  this.originalData?.length-1;

  }

  removeDupForCount(data: any) {

    const filteredData = data.filter((value, index, self) => {
      return index === self.findIndex((t) => t.regionCode === value.regionCode);
    });

    return filteredData.length;
  }


  setDataForTotalGridAndAll(data: any) {
    // const missingData = this.getMissingDistricts(data);
    // data.push(...missingData);
    data = data?.filter(x=> !['83']?.includes(x.regionCode));
    data?.forEach((element, index) => {
      // const dealer = this.sharedDataService.dealerData?.find(x => x.areaRegionNo === element.regionCode);
      // if (dealer) {
      //   data[index]['dealerCd'] = dealer.dealerCode;
      // } else {
      //   data[index]['dealerCd'] = null;
      // }
      data[index]['dailyGoldPlusSilverSales'] = Number(element?.dailyGoldSales || 0) + Number(element?.dailySilverSales || 0);
      data[index]['monthlyGoldPlusSilverSales'] = Number(element?.monthlyGoldSales || 0) + Number(element?.monthlySilverSales || 0);
      data[index]['totalYearlySilverGoldSales'] = Number(element?.yearlyGoldSales || 0) + Number(element?.yearlySilverSales || 0);
    });

    // return data;
    const truncatedData = this.truncateAllValuesOfArray(data);
    // return this.totalCount(data);
    return this.totalCount(truncatedData);
  }

  truncateAllValuesOfArray(array){
    for (const item of array) {
      for (const key in item) {
        if (typeof item[key] === 'number') {
          item[key] = this.truncateDecimalPlaces(item[key], 2);
        }
      }
    }
    return array;
  }

  truncateDecimalPlaces(num: number, decimalPlaces: number): number {
    return Math.floor(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }

totalCount(returnArray: any[]) {
  
  
  // const item2: any = { regionName: 'TOTAL', regionCode: 'TOTAL' };
  const item2: any = { dealerCd: 'TOTAL', dealerName: 'TOTAL' };

  const accumulateProperty = (property: string, value: number) => {
      const accumulatedValue = (item2[property] || 0) + value;
      item2[property] = this.truncateToTwoDecimalPlacesString(accumulatedValue);
  };

  for (let j = 0; j < returnArray.length; j++) {
      const _dealer = this.sharedDataService.dealerData.find(t => t?.dealerCode === returnArray[j]?.dealerCd);
      
      if (!_dealer || _dealer.areaRegionNo === '83') {
          continue;
      }

      this.keysArray?.forEach(property => {
        if(!['regionCode', 'dealerCd', 'districtCode'].includes(property)){
        // accumulateProperty(property, returnArray[j][property] || 0);
        const value = returnArray[j][property] || 0;
        const truncatedValue = this.truncateToTwoDecimalPlacesString(value); // Truncate the value
        accumulateProperty(property, truncatedValue);
      }
      });
  }

  // Push the total item into the array
  const updatedReturnArray = [...returnArray, item2];
  // Calculate averages using the updated array without filtering out 'TOTAL';
  
  const avg = this.calculateAverages(updatedReturnArray, false);
  
  // Return the updated array with totals and averages
  return avg;
}



calculateAverages(returnArray: any, isTotalGrid:boolean) {
  const averageItem: any = { dealerCd: 'AVERAGE', dealerName: 'AVERAGE'};  
  const totalRow = returnArray.find(item => item.dealerName === 'TOTAL');
  
  // Count of valid entries (excluding the total row)
  // const validCount = returnArray.filter(item => item.regionCode !== 'TOTAL' && item.regionCode !== 'AVERAGE').length;
  // const validCount = returnArray.filter(item => item.dealerCd !== 'TOTAL' && item.dealerName !== 'AVERAGE').length;
  const validCount = returnArray.filter(item => item.dealerName !== 'AVERAGE').length;
  
  if(isTotalGrid){
    this.totalGridSaleCount = validCount;
  } else {
    this.totalSaleCount = validCount;
  }

  if (totalRow && validCount > 0) {
      // Calculate averages based on the total row
      for (const key in totalRow) {
          if (typeof totalRow[key] === 'number') {
              const average = totalRow[key] / validCount;
              averageItem[key] = this.truncateToTwoDecimalPlacesString(average);
          }
      }
  } else {
      // Handle case where there are no valid entries
      for (const key in averageItem) {
          averageItem[key] = 0; // Default to 0 if no valid entries
      }
  }

  // Append averageItem to the array
  return [...returnArray, averageItem];
}

 truncateToTwoDecimalPlacesString(num: number): number {
    const numStr = num.toString();
    const decimalIndex = numStr.indexOf('.');
  
    if (decimalIndex === -1) {
      return num;  
    }
    const numDecimals = numStr.length - decimalIndex - 1;
  
    if (numDecimals <= 2) {
      return num; 
    }
  
    return Math.floor(num * 100) / 100; 
  //   if (Number.isInteger(num)) {
  //     return num; // Return the integer as is
  // }

  // // Truncate the number to two decimal places
  // return Math.floor(num * 100) / 100;
  
}

}


