import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../services/common/auth-guard';
// import { UCDealerWiseSalesCMPReportGridComponent } from './dealer-wise-sales-cmp-report-grid.component';
import { UCDealerWiseSalesCMPPageReportComponent } from './dealer-wise-sales-cmp-report-page.component';


const inventorySummaryRoutes: Routes = [
    {
        path: 'dealerWiseSalesComparisionReport',
        // component: UCDealerWiseSalesCMPReportGridComponent,
        component: UCDealerWiseSalesCMPPageReportComponent,
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(inventorySummaryRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class UCDealerWiseSalesCMPReportRoutingModule {

}
