'use strict';
import * as models from './models';

export interface UCInventorySummaryReport {
  regionNumber?: any;
  lotDays?: number;
  stockNumber?: string;
  // Vehicle Identification Number
  vin?: string;
  // dis
  dis?: string;
  // make Code
  makeCode?: string;
  // sold to dealer
  soldToDealer?: string;
  // model number
  model?: models.Model;
  // accessories details
  accessoriesDetails?: models.AccessoryDetails[];
  // accessories names
  accessories?: string;
  // certification details
  certification?: models.CertificationDetails;
  // price details
  price?: models.Price;
  // open campaign status
  openCampaignStatus?: string;
  // acquired date
  acquiredDate?: string;
  // mileage
  mileage?: number;
  // dofu
  dofu?: string;
  // comments
  comments?: string;
  // decerify reason
  deCertifyReason?: string;
  // Dealer Code that the vehicle is allocated to
  dealerCd?: string;
  districtNumber?: string;
  // dealer name
  dealerName?: string;
  // exterior color
  extColor?: models.Color;
  // interior color
  intColor?: models.Color;
  // selected
  selected?: boolean;
  // car fax flag
  carFaxFlag?: string;
  // certification status
  certificationStatus?: string;
  // certification status
  certificationNumber?: string;
  certificationType?: string;

  updatedAt?: string;

  rdrDetail?: models.RdrDetail;

  areaRegionNo?: string;

  regionName?: string;

  createdBy?: string;

  modelDesc?: string;

  count?: string;

  inspection?: models.Inspection;

  repairOrder?: models.RepairOrder;

  decertifiedAt?: string;

  reason?: string;

  reportData?: any;

  startDate?: any;

  endDate?: any;

  type?: any;

  acquiredTOCert?: string;
  certTOSale?: string;
  dailyCertifiedSalesCount?: number;
  goldDailyCertifiedSalesCount?: number;
  silverDailyCertifiedSalesCount?: number;
  certifiedSalesCount?: number;
  goldCertifiedSalesCount?: number;
  silverCertifiedSalesCount?: number;
  dailyCertifiedInventory?: number;
  goldDailyCertifiedInventory?: number;
  silverDailyCertifiedInventory?: number;
  goldCertifiedInventory?: number;
  silverCertifiedInventory?: number;
  certifiedInventory?: number;
  invCount?: number;
  goldInvCount?: number;
  silverInvCount?: number;
  certChange?: number;
  certChangePercent?: number;
  currentYearObjective?: number;
  prevYearlyCertifiedInventory?: number;
  prevYearlyCertifiedSalesCount?: number;
  prevYearlyInvCount?: number;
  salesChange?: number;
  salesChangePercent?: number;
  certAfterSaleCount?: number;
  certAfterSalePercent?: number;
  sameDayCertCount?: number;
  sameDayCertPercent?: number;
  totSalesCount?: number;
  totalPercent?: number;
  campaignNumber ?: number;

  // regionCode?: number;
  districts ?: models.Districts[];
   regionCode?: string;
  acqToSaleAvgMTD?: string;
  acqToSaleAvgYTD?: string;
  certToSaleAvgMTD?: string;
  certToSaleAvgYTD?: string;
  dailyGoldSales?: string;
  dailySilverSales?: string;
  daySupply?: string;
  districtCode?: string;
  invLessThan60Days?: string;
  monthlyGoldSales?: string;
  monthlySilverSales?: string;
  prevYrSameDaySales?: string;
  prevYrSameMonthSales?: string;
  prevYrTotalSales?: string;
  turnRate?: string;
  yearlyGoldSales?: string;
  yearlySilverSales?: string;
  daySupplyFullInv?: string;
  onHandfullInv?: string;
  previousYrSameDayOnHandFullInv?: string;
  previousMonthSameDayOnHandFullInv?: string;
  previousYrSameDayOnHandLessThan60Days?: string;
  previousMonthSameDayOnHandLessThan60Days?: string;
  currentMonthRegionObjective?: string;

}
