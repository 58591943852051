import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SharedDataService } from '../../../services/common/shared-data.service';

@Component({
  selector: 'uc-region-dashboard-menubar-panel',
  templateUrl: 'region-dashboard-menubar-panel.component.html',
  styleUrls: ['./region-dashboard-menubar-panel.component.scss']
})

export class UCRegionDashboardMenubarPanelComponent {
  @Output() refreshScreenEvent = new EventEmitter<number>();

  // Event to emit when clear all button is clicked
  @Output() clearAllButtonEvent = new EventEmitter();
  @Output() exportPdfEvent = new EventEmitter<number>();

  @Output() exportExcelEvent = new EventEmitter<number>();
  // Boolean variable to show/hide the clear all button
  @Input() showClearAllButton: boolean;
  @Input() showTotalRecords: boolean = false;
  // Variable to display the total inventory count
  @Input() totalInventoriesCount: Number;

  constructor(public sharedDataService: SharedDataService) {

  }
  /**
   * Method to handle when clear all button is clicked
   */
  clearAllButtonClicked(event: any) {
    this.clearAllButtonEvent.emit();
  }

  refreshScreen() {
    this.refreshScreenEvent.emit();
  }

  exportPdf() {
    this.exportPdfEvent.emit();
  }

  exportExcel() {
    this.exportExcelEvent.emit();
  }
}
