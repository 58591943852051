import { Component, Input, Output, EventEmitter, ViewChild, OnInit, ElementRef } from '@angular/core';
import { UCInventorySummary } from '../../../services/model/InventorySummary';
import { ViewConstants } from '../../../shared/shared.constants';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import * as models from '../../../services/model/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { cloneDeep, isNil, sortBy } from 'lodash';



import * as messageActions from '../../../actions/message-data.action';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';

@Component({
  selector: 'uc-delaer-wise-sales-cmp-grid',
  templateUrl: 'dealer-wise-sales-cmp-report-grid.component.html',
  styleUrls: ['./dealer-wise-sales-cmp-report-grid.component.scss']
})

  export class UCDealerWiseSalesCMPReportGridComponent {
    @Input() columnDefs: any[] = [];
    @Input() totalSaleCount: number;
  @Input() totalGridSaleCount: number;
    @Input() rows: Array<UCInventorySummary>;
    public sortColumnsList: Array<any> = [];
    @Output() onClickedEvent = new EventEmitter<any>();
    @Output() navigateToVehicleDetailEvent = new EventEmitter<any>();
    @Input() selectedMonth: string;
    @Input() selectedYear: string;
    @Input() regionSelection: string;
    @Input() districtSelection: string;
    // to clear the current sorting for all columns
    @Input('clearSort')
    set clearSort(val: boolean) {
      if (val) {
        this.sortColumnsList = [];
      }
    }
  
    @ViewChild('inventoryTable') inventoryTable: DatatableComponent;
    @Output() fetchObjectiveEvent = new EventEmitter<any>();
    @ViewChild('inputFile') myInputVariable: ElementRef;
  
    @Output() applySortEvent = new EventEmitter<any>();
    @Output() applyFilterEvent = new EventEmitter<any>();
  
  
    constructor(public sharedFunctionService: SharedFunctionService, public router: Router,
      public sharedDataService: SharedDataService) {
    }
  
    /**
     * Method to apply the sorting in the grid
     */
    applySort(columnName: string) {
      let sortOrder = ViewConstants.ASC;
      if (this.sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
        sortOrder = this.sortColumnsList.find(x => x.columnName === columnName)
          .sortOrder === ViewConstants.ASC ? ViewConstants.DESC : ViewConstants.ASC;
      }
      this.sortColumnsList = [];
      this.sortColumnsList.push({ columnName: columnName, sortOrder: sortOrder });
      this.applySortEvent.emit({ columnName: columnName, sortOrder: sortOrder });
    }
  
    applyFilter(event: any, columnName: string, filterType: models.FilterType) {
      //TODO: Skipping this functionality for angular update
      // if (!this.inventoryTable.offsetX)
      //   this.inventoryTable.offsetX = 0;
      let filter: models.Filter = {};
      filter.columnname = columnName;
      filter.filterValue = event.target.value;
      let array = String(event.target.value).split(' ');
      filter.filterType = filterType;
      this.applyFilterEvent.emit(filter);
    }
  
    /**
     * Grid Row Select/Unselect Event
     */
    onClicked(checkboxChange: any, row: any) {
      let data = {
        checkBoxChecked: checkboxChange.source.checked,
        row: row
      };
      this.onClickedEvent.emit(data);
    }
  
  
  
    navigateToVehicleDetailScreen(event: any) {
      let selectedVehicleRoutes: models.VehicleRoute = {};
      selectedVehicleRoutes.vin = event;
      this.sharedDataService.setSelectedVehicle(selectedVehicleRoutes);
      this.navigateToVehicleDetailEvent.emit(event);
      // this.router.navigate(['/vehicle/' + event + '/' + this.sharedDataService.dealerCode]);
    }
    fetchObjective() {
      let data = {
        month: this.selectedMonth,
        year: this.selectedYear,
        regionCode: this.regionSelection,
        district: this.districtSelection
      };
      this.fetchObjectiveEvent.emit(data);
      this.myInputVariable.nativeElement.value = '';
    }
  }


