import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCDealerWiseSalesCMPReportRoutingModule } from './dealer-wise-sales-cmp-report-routing.module';
import { UCDealerWiseSalesCMPReportGridComponent } from './dealer-wise-sales-cmp-report-grid.component';
import { UCDealerWiseSalesCMPPageReportComponent } from './dealer-wise-sales-cmp-report-page.component';

@NgModule({
  imports: [
    SharedModule,
    UCDealerWiseSalesCMPReportRoutingModule
  ],
  exports: [
    UCDealerWiseSalesCMPPageReportComponent
  ],
  declarations: [
    UCDealerWiseSalesCMPPageReportComponent,
    UCDealerWiseSalesCMPReportGridComponent
  ]
})

export class UCDealerWiseReportModule {

}
