<div class="margin1">
  <div>
    <div >
      <strong>CVA REPORTS</strong>
    </div>
  </div>

  <div class="ucm-reports-wrapper">
    <div class="reportSection">
        <p>
          <strong>Sales and Accounting</strong>
        </p>
        <mat-radio-group (change)="changeReportSelection('S')" aria-labelledby="example-radio-group-label"
          class="example-radio-group" [(ngModel)]="selectedSalesReport">
          <mat-radio-button [disabled]='report.disabled' class="example-radio-button"
            *ngFor="let report of salesReportsCopy" [value]="report.mode">
            {{report.name}}
          </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="reportSection" >
      
      <label><strong>Inventory</strong></label>
      <div>
        <mat-radio-group (change)="changeReportSelection('I')" aria-labelledby="example-radio-group-label"
          class="example-radio-group" [(ngModel)]="selectedInventoryReport">
          <mat-radio-button [disabled]='report.disabled' class="example-radio-button"
            *ngFor="let report of inventoryReportsCopy" [value]="report.mode">
            {{report.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  

  <div class="marginT1 width100" fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="column" fxLayoutAlign="end center">
      <button [disabled]="(!selectedInventoryReport && !selectedSalesReport && !selectedAccountingReport)"
        (click)="proceedtoReportScreen()" mat-button mat-raised-button>PROCEED</button>
    </div>
  </div>
</div>