<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite" *ngIf="!isRegionalAndDistrictWiseFilterSelected">
    <mat-card fxFlex="row" fxFlex="100">
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="25" style="padding: 5px;margin-left: 2%">
        <label>Region Selection</label>
      </div>
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
          class="mat-no-underline" name="region" [(ngModel)]="regionSelection" #region="ngModel" (dateChanged)="dateFilter($event,'regionSelection')" (selectionChange)="getDistricts($event)" [disabled]="sharedDataService?.isGSTUser || sharedDataService?.isSETUser">
          <mat-option [value]="">
            -- select --
          </mat-option>
          <mat-option *ngFor="let i of regionItems" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="25" style="padding: 5px;margin-left: 2%">
        <label>District</label>
      </div>
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
          class="mat-no-underline" name="district" [(ngModel)]="districtSelection" #district="ngModel" (dateChanged)="dateFilter($event,'districtSelection')">
          <mat-option [value]="">
            -- select --
          </mat-option>
          <mat-option *ngFor="let i of districtItems" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="18" style="padding: 5px;margin-left: 2%">
        <label>{{labelText}}</label>
      </div>
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="23" style="padding: 5px;">
        <my-date-picker style="width:100%" placeholder="From Date" name="retailDate" id="retailDate"
          [options]="myDatePickerOptions" [locale]="'en'" [selDate]="fromDate | date :'MM/dd/yyyy'"
          (dateChanged)="dateFilter($event,'fromDate')"></my-date-picker>
      </div>
  
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="27" style="padding: 5px;">
        <my-date-picker style="width:100%" placeholder="To Date" name="retailDate" id="retailDate"
          [options]="myDatePickerOptions" [locale]="'en'" [selDate]="toDate | date :'MM/dd/yyyy'"
          (dateChanged)="dateFilter($event,'toDate')"></my-date-picker>
      </div>
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;">
        <button [disabled]="!isDateSelected" (click)="filterValueChanged();" mat-button mat-raised-button>Apply</button>
      </div>
    </mat-card>
  
  </div>  
<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite" *ngIf="isRegionalAndDistrictWiseFilterSelected">
    <mat-card fxFlex="row" fxFlex="100">
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="25" style="padding: 5px;margin-left: 2%" *ngIf="isRegionFilter">
        <label>Region Selection</label>
      </div>
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px" *ngIf="isRegionFilter">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
          class="mat-no-underline" name="region" [(ngModel)]="regionSelection" #region="ngModel" (dateChanged)="dateFilter($event,'regionSelection')" (selectionChange)="getDistricts($event)" [disabled]="sharedDataService?.isGSTUser || sharedDataService?.isSETUser">
          <mat-option [value]="">
            -- select --
          </mat-option>
          <mat-option *ngFor="let i of regionItems" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
  
      <!-- <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="25" style="padding: 5px;margin-left: 2%">
        <label>District</label>
      </div>
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
          class="mat-no-underline" name="district" [(ngModel)]="districtSelection" #district="ngModel" (dateChanged)="dateFilter($event,'districtSelection')">
          <mat-option [value]="">
            -- select --
          </mat-option>
          <mat-option *ngFor="let i of districtItems" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="18" style="padding: 5px;margin-left: 2%">
        <label>{{labelText}}</label>
      </div> -->
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="23" style="padding: 5px;" *ngIf="!isRegionFilter">
        <my-date-picker style="width:100%;margin-right: -130px;" placeholder="From Date" name="retailDate" id="retailDate"
          [options]="myDatePickerOptions" [locale]="'en'" [selDate]="fromDate | date :'MM/dd/yyyy'"
          (dateChanged)="dateFilter($event,'fromDate')"></my-date-picker>
      </div>
  
      <!-- <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="27" style="padding: 5px;" *ngIf="!isRegionFilter">
        <my-date-picker style="width:100%" placeholder="To Date" name="retailDate" id="retailDate"
          [options]="myDatePickerOptions" [locale]="'en'" [selDate]="toDate | date :'MM/dd/yyyy'"
          (dateChanged)="dateFilter($event,'toDate')"></my-date-picker>
      </div> -->
  
      <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;" *ngIf="!isRegionFilter">
        <button [disabled]="!isDateSelected" (click)="filterValueChanged();" mat-button mat-raised-button>Apply</button>
      </div>
    </mat-card>
  
  </div>  