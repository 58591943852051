export class ReportData {
  static salesReports: any = [
    // {
    //   'name': 'TMS TCUV SALES AND STOCK SUMMARY REPORT - [R20702]',
    //   'disabled': false,
    //   'mode': '25',
    //   'makeCode': 'TOYOTA'
    // },
    {
      'name': 'Lexus Certified Pre-owned Sales Consultant RDR Summary Report - [R21902]',
      'disabled': false,
      'mode': '18',
      'makeCode': 'LEXUS'
    },
    {
      'name': 'Toyota Certified Used Vehicles Sales Consultant RDR Summary Report - [R21900]',
      'disabled': false,
      'mode': '18',
      'makeCode': 'TOYOTA'
    },
    {
      'name': 'Lexus Certified Pre-owned Sales Consultant RDR Detail Report - [R21902]',
      'disabled': false,
      'mode': '19',
      'makeCode': 'LEXUS'
    },
    {
      'name': 'Toyota Certified Used Vehicles Sales Consultant RDR Detail Report - [R21900]',
      'disabled': false,
      'mode': '19',
      'makeCode': 'TOYOTA'
    },
    {
      'name': 'Summary of used cars sales at district level for Lexus division - [R18875]',
      'disabled': false,
      'mode': '24',
      'makeCode': 'LEXUS'
    },
    {
      'name': 'Lexus Pre-Owned Daily Sales Without Wholesales - [R18875]', 'disabled': false,
      'mode': '15',
      'makeCode': 'LEXUS'
    },
    {
      'name':
        'Summary of Sales & Stock information' +
        'by dealership for each region for Lexus dealers - [R20244]',
      'disabled': false,
      'mode': '14',
      'makeCode': 'LEXUS'
    },
    {
      'name': 'Lexus Division Pre Owned Daily Sales report by Series for each dealer - [R21868]',
      'disabled': false,
      'mode': '13',
      'makeCode': 'LEXUS'
    },
    {
      'name': 'LCPO Sales Comparison Report By Model Series/Model Year - [R21387, R21415]',
      'disabled': false,
      'mode': '11',
      'makeCode': 'LEXUS'
    },
    // {
    //   'name': 'LCPO Y-T-D Sales Comparison Report By Model Series/Model Year',
    //   'disabled': false,
    //   'mode': '12',
    //   'makeCode': 'LEXUS'
    // },
    {
      'name': 'Month to Month TCUV Sales comparison between current and previous month - [R20956]',
      'disabled': false,
      'mode': '10',
      'makeCode': 'TOYOTA'
    },
    {
      'name':
        'Month to Month TCUV Sales comparison between current and previous' +
        'month for dealers whose sales comparison is more than 10 - [R21847]',
      'disabled': false,
      'mode': '10y',
      'makeCode': 'TOYOTA'
    }, {
      'name': 'Average Sales Report',
      'disabled': false,
      'mode': '30',
      'makeCode': 'TOYOTA'
    },
    {
      'name': 'Average Sales report',
      'disabled': false,
      'mode': '30',
      'makeCode': 'LEXUS'
    }, 
    {
      'name': 'Daily Sales Breakdown GOLD/SILVER Report',
      'disabled': false,
      'mode': '34',
      'makeCode': 'TOYOTA'
    },
    {
      'name': 'Year to Year TCUV Sales Comparison Report',
      'disabled': false,
      'mode': '35',
      'makeCode': 'TOYOTA'
    },
    {
      'name': 'Same Day Cert Report',
      'disabled': false,
      'mode': '36',
      'makeCode': 'TOYOTA'
    },
    {
      'name': 'Same Day Cert Report',
      'disabled': false,
      'mode': '36',
      'makeCode': 'LEXUS'
    }
    // ,
    // {
    //   // 'name': 'Regional And District Wise Sales Comparison Cert Report',
    //   'name': 'TCUV SALES AND STOCK SUMMARY REPORT',
    //   'disabled': false,
    //   'mode': '37',
    //   'makeCode': 'TOYOTA'
    // },
    // {
    //   // 'name': 'Regional And District Wise Sales Comparison Cert Report',
    //   'name': 'TCUV SALES AND STOCK SUMMARY REPORT',
    //   'disabled': false,
    //   'mode': '37',
    //   'makeCode': 'LEXUS'
    // }
  ];

  static accountingReports: any =
    [
      //   {
      //   'name':
      //     'Toyota Certified Used Vehicles National' +
      // ' Model Wise RDR Summary Report - [R20952C, R0953C]',
      //   'disabled': false,
      //   'mode': '6',
      //   'makeCode': 'TOYOTA'
      // },
      {
        'name': 'Toyota Certified Used Vehicles National/Regional '
          + 'Model Line RDR Report - [R0954C, R0955C]',
        'disabled': false,
        'mode': '8',
        'makeCode': 'TOYOTA'
      },
      {
        'name':
          'Toyota Certified Used Vehicles Regional Model Line RDR Report by' +
          'dealer - [R0954D, R0955D]',
        'disabled': false,
        'mode': '9',
        'makeCode': 'TOYOTA'
      },
      {
        'name':
          'Lexus Certified Used Vehicles Regional Model Line RDR Report by' +
          'dealer - [R0954D, R0955D]',
        'disabled': false,
        'mode': '9',
        'makeCode': 'LEXUS'
      },
      {
        'name':
          'Toyota Certified TRAC Vehicles Retail Sales  Summary [R22138, R22140]',
        'disabled': false,
        'mode': '20',
        'makeCode': 'TOYOTA'
      },
      {
        'name':
          'Toyota Certified TRAC Vehicles Retail Sales [R22137, R22139]',
        'disabled': false,
        'mode': '22',
        'makeCode': 'TOYOTA'
      },
      {
        'name':
          'Toyota Certified Used Vehicles Retail Sales having mileage ' +
          'greater than 65000 - [R22203, R22204]',
        'disabled': false,
        'mode': '16',
        'makeCode': 'TOYOTA'
      },
      // {
      //   'name': 'Lexus Certified Pre Owned National Model Wise ' +
      // 'RDR Summary Report - [R22848, R22849]',
      //   'disabled': false,
      //   'mode': '6',
      //   'makeCode': 'LEXUS'
      // },
      {
        'name': 'Lexus Certified Preowned Vehicles National/' +
          'Regional Model Line RDR Report - [R22850, R22851]',
        'disabled': false,
        'mode': '8',
        'makeCode': 'LEXUS'
      }, {
        'name': 'RDR Data Report',
        'disabled': false,
        'mode': '31',
        'makeCode': 'TOYOTA'
      },
      , {
        'name': 'RDR Data report',
        'disabled': false,
        'mode': '31',
        'makeCode': 'LEXUS'
      }];

  static inventoryReports: any = [{
    'name': 'Lexus Pre-Owned Vehicles Daily Recap Report - [R18279] ',
    'disabled': false,
    'mode': '26',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Toyota Inventory Exception Report for certified units - [R21912]',
    'disabled': false,
    'mode': '5',
    'makeCode': 'TOYOTA'
  },
  {
    'name': 'Lexus Inventory Exception Report for certified units - [R21912]',
    'disabled': false,
    'mode': '5',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Toyota Certified Used Vehicles Certification report - [R20730]',
    'disabled': false,
    'mode': '4',
    'makeCode': 'TOYOTA'
  },
  {
    'name': 'Lexus Certified Pre Owne Vehicles Certification report - [R20730]',
    'disabled': false,
    'mode': '4',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Toyota Certified Used Vehicles Certification report (Internal Associates) - [R23297]',
    'disabled': false,
    'mode': '3',
    'makeCode': 'TOYOTA'
  },
  {
    'name':
      'Lexus Certified Pre Owned Vehicles Certification report (Internal Associates) - [R20750]',
    'disabled': false,
    'mode': '3',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Toyota Deleted Certification Report - [R23967, R23969, R22624]',
    'disabled': false,
    'mode': '1',
    'makeCode': 'TOYOTA'
  },
  {
    'name': 'Lexus Deleted Certification Report - [R23967, R23969, R22624]',
    'disabled': false,
    'mode': '1',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Toyota Deleted Certification Summary Report - [R23968, R23970, R22625]',
    'disabled': false,
    'mode': '2',
    'makeCode': 'TOYOTA'
  },
  {
    'name': 'Lexus Deleted Certification Summary Report - [R23968, R23970, R22625]',
    'disabled': false,
    'mode': '2',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Recall Report',
    'disabled': false,
    'mode': '28',
    'makeCode': 'TOYOTA'
  },
  {
    'name': 'Recall report',
    'disabled': false,
    'mode': '28',
    'makeCode': 'LEXUS'
  },
  {
    'name': 'Inventory Report',
    'disabled': false,
    'mode': '29',
    'makeCode': 'TOYOTA'
  },
  {
    'name': 'Inventory report',
    'disabled': false,
    'mode': '29',
    'makeCode': 'LEXUS'
  }
];

  static monthDetails: any = [{
    'name': 'January',
    'disabled': false,
    'value': '1'
  },
  {
    'name': 'February',
    'disabled': false,
    'value': '2'
  },
  {
    'name': 'March',
    'disabled': false,
    'value': '3'
  },
  {
    'name': 'April',
    'disabled': false,
    'value': '4'
  },
  {
    'name': 'May',
    'disabled': false,
    'value': '5'
  },
  {
    'name': 'June',
    'disabled': false,
    'value': '6'
  },
  {
    'name': 'July',
    'disabled': false,
    'value': '7'
  },
  {
    'name': 'August',
    'disabled': false,
    'value': '8'
  },
  {
    'name': 'September',
    'disabled': false,
    'value': '9'
  },
  {
    'name': 'October',
    'disabled': false,
    'value': '10'
  },
  {
    'name': 'November',
    'disabled': false,
    'value': '11'
  },
  {
    'name': 'December',
    'disabled': false,
    'value': '12'
  }];
}
