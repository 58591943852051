import { NgModule } from '@angular/core';
import { UCRegionalAndDistrictSalesCMPReportRoutingModule }
  from './regional-disrtict-wise-sales-cmp-report-routing.module';
import { UCRegionalAndDistrictWiseSalesCMPPageReportComponent } from './regional-disrtict-wise-sales-cmp-report-page.component';
import { UCRegionalAndDistrictWiseSalesCMPReportGridComponent }
  from './regional-disrtict-wise-sales-cmp-report-grid.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    UCRegionalAndDistrictSalesCMPReportRoutingModule
  ],
  exports: [
    UCRegionalAndDistrictWiseSalesCMPPageReportComponent
  ],
  declarations: [
    UCRegionalAndDistrictWiseSalesCMPPageReportComponent,
    UCRegionalAndDistrictWiseSalesCMPReportGridComponent
  ]
})

export class UCReginoalAndDistrictReportModule {

}
