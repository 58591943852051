

import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/vehicle-history.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { ViewConstants } from '../shared/shared.constants';
import { SharedDataService } from '../services/common/shared-data.service';
import { DatePipe } from '@angular/common';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface VehicleHistoryState {
  vehicleHistory: viewModels.VehicleHistoryView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: VehicleHistoryState = {
  vehicleHistory: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.VehicleHistoryView {
  let vehicleHistory: viewModels.VehicleHistoryView =
    <viewModels.VehicleHistoryView>new Object();
  vehicleHistory.vehicleHistory = new Array<models.VehicleHistory>();
  vehicleHistory.message = null;
  return vehicleHistory;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): VehicleHistoryState {
  switch (action.type) {
    case detailActions.actionTypes.GET_VEHICLE_HISTORY: {
      // return the Vehicle Detail data
      // return { vehicleDetail: setDefaulValue() };
      return state;
    }
    case detailActions.actionTypes.GET_VEHICLE_HISTORY_SUCCESS: {
      // return the Vehicle Detail data
      let vehicleData: any = formatDataforDetailsView(
        cloneDeep(action.payload));
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleHistory, {
        vehicleData: vehicleData
      });
      vehicleDetailData.pageType = ViewConstants.UPDATE;
      vehicleDetailData.message = null;
      return { vehicleHistory: vehicleDetailData.vehicleData };
    }
    case detailActions.actionTypes.GET_VEHICLE_HISTORY_FAIL: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.vehicleHistory, {
          vehicleData: { vin: action.payload.vehicleData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { vehicleHistory: vehicleDetailData };
      }

    }
    case detailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { vehicleHistory: setDefaulValue() };
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getVehicleHistoryfromVin(state: VehicleHistoryState) {
  return state.vehicleHistory;
}

/**
 * Method used to frame the required state for the vehicle detail screen
 */
function formatDataforDetailsView(data: any): viewModels.VehicleHistoryView {
  let vehicleHistoryData: viewModels.VehicleHistoryView = setDefaulValue();
  if (data && data.length > 0) {
    let historyData: any[] = [];
    data.map(e => {
      let historyEvent: models.VehicleHistory = {};
      historyEvent.vin = e._source.vin;
      historyEvent.model = e._source.model;
      historyEvent.isNew = e._source.isNew;
      historyEvent.dealerCd = e._source.dealerCd;
      let makeCode = e._source.makeCode;
      let eventsData: any[] = [];
      if (historyEvent.isNew === true) {
        e._source.auditTrail.map(element => {
          if ((isNil(element.updatedAt) && isNil(element.updatedBy))
            && (isNil(element.vehicleDecertification))) {
            let event: any = {};
            event.type = 'Vehicle InStock';
            event.date = element.createdAt;
            event.userId = element.createdBy;
            if (element.certificationNumber !== undefined &&
              !isNil(element.certificationNumber)) {
              event.value = '<b>Certification Number</b>: '
                + element.certificationNumber;
            }
            eventsData.push(event);
          } else if (isNil(element.certificationNumber)) {
            let event: any = {};
            event.type = 'Vehicle InStock';
            event.date = element.createdAt;
            event.userId = element.createdBy;
            eventsData.push(event);
          } else if (!isNil(element.certificationNumber)) {
            let event: any = {};
            event.type = 'Vehicle Certified';

            if (!isNil(element.certification) &&
              !isNil(element.certification.forceCertificationFlag) &&
              element.certification.forceCertificationFlag === 'true') {
              event.value = '<b>Certification Type</b>: Force Certified';
            } else {
              event.value = '<b>Certification Type</b>: Regular Certified';
            }

            if (element.certificationNumber !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Certification Number</b>: '
                + element.certificationNumber;
            }

            if (!isNil(element.certification) &&
              element.certification.certificationDate !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Certification Date</b>: '
                + new Date(element.certification.certificationDate).
                  toLocaleDateString() + ' ' +
                new Date(element.certification.certificationDate).
                  toLocaleTimeString();
            }

            if (!isNil(element.certification) &&
              element.certification.planAmount !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Plan Amount</b>: ' +
                element.certification.planAmount;
            }

            if (!isNil(element.certification) &&
              element.certification.warrantyCode !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Warranty Code</b>: ' +
                element.certification.warrantyCode;
            }

            if (!isNil(element.certification) &&
              element.certification.warrantyExpiryDate !== undefined) {
              event.value = event.value +
                ',&nbsp;&nbsp;<b>Warranty Expiry Date</b>: ' +
                new Date(element.certification.warrantyExpiryDate).
                  toLocaleDateString() + ' ' +
                new Date(element.certification.warrantyExpiryDate).
                  toLocaleTimeString();
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: ' +
                  element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }

            if (element.isAllowOpenCampaign !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
              else
                event.value = '<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
            }

            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.updatedAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.vehicleDecertification)) {
            let event: any = {};
            event.type = 'Vehicle Decertification';
            event.date = element.vehicleDecertification.LAST_UPDATE_TIMESTAMP;

            if (!isNil(element.vehicleDecertification.BLOCK_CERTIFICATION_FLAG) &&
              element.vehicleDecertification.BLOCK_CERTIFICATION_FLAG === 'Y') {
              event.userId = element.vehicleDecertification.LAST_UPDATED_ID;
              event.value = '<b>Type</b>: Blocked' + ',&nbsp;&nbsp;<b>Reason</b>: '
                + element.vehicleDecertification.DECERTIFICATION_REASON_CODE
                + ',&nbsp;&nbsp;<b>Source</b>: '
                + element.vehicleDecertification.DECERTIFICATION_SOURCE_CODE
                + ',&nbsp;&nbsp;<b>Comment</b>: '
                + element.vehicleDecertification.DECERTIFICATION_COMMENTS;
            } else {
              event.value = '<b>Type</b>: Un Blocked'
                + ',&nbsp;&nbsp;<b>Comment</b>: '
                + element.vehicleDecertification.UNBLOCK_COMMENT;
            }

            eventsData.push(event);
          }
          historyEvent.events = eventsData;
        });
      } else {
        const datepipe = new DatePipe('en-US');
        e._source.auditTrail.map(element => {
          if (((isNil(element.updatedAt) && isNil(element.updatedBy)
            && isNil(element.rdrDetail)) && (isNil(element.vehicleDecertification)))) {
            let event: any = {};
            event.type = 'Vehicle InStock';
            event.date = element.createdAt;
            event.userId = element.createdBy;
            if (element.certificationNumber !== undefined &&
              !isNil(element.certificationNumber)) {
              event.value = '<b>Certification Number</b>: ' +
                element.certificationNumber;
            }
            eventsData.push(event);
          } else if (!isNil(element.updatedAt) && isNil(element.certificationStatus)
            && isNil(element.inventoryStatus)
            && isNil(element.rdrDetail) && isNil(element.tradeStatus)
            && isNil(element.vehicleDecertification)) {
            let event: any = {};
            event.type = 'Vehicle Updated';
            if (element.mileage !== undefined) {
              event.value = '<b>Mileage</b>: ' + element.mileage;
            }

            if (element.acquiredMethod !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Aquired Method</b>: '
                  + element.acquiredMethod;
              else
                event.value = '<b>Aquired Method</b>: ' + element.acquiredMethod;
            }

            if (element.acquiredDate !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Aquired Date</b>: ' +
                  new Date(element.acquiredDate).
                    toLocaleDateString() + ' ' +
                  new Date(element.acquiredDate).
                    toLocaleTimeString();
              else
                event.value = '<b>Aquired Date</b>: ' +
                  new Date(element.acquiredDate).
                    toLocaleDateString() + ' ' +
                  new Date(element.acquiredDate).
                    toLocaleTimeString();
            }

            if (element.acvPrice !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Aquired Price</b>: ' +
                  element.acvPrice;
              else
                event.value = '<b>Aquired Price</b>: ' + element.acvPrice;
            }

            if (element.retailAmount !== undefined) {
              if (event.value !== undefined)
                event.value = event.value +
                  ',&nbsp;&nbsp;<b>Retail Price</b>: ' + element.retailAmount;
              else
                event.value = '<b>Retail Price</b>: ' + element.retailAmount;
            }

            if (element.dealerTradeAmount !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Trade Price</b>: '
                  + element.dealerTradeAmount;
              else
                event.value = '<b>Trade Price</b>: ' + element.dealerTradeAmount;
            }

            if (element.reconditionCost !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Recondition Cost</b>: '
                  + element.reconditionCost;
              else
                event.value = '<b>Recondition Cost</b>: ' + element.reconditionCost;
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: ' +
                  element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;

            }

            if (element.isAllowOpenCampaign !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
              else
                event.value = '<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
            }

            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.updatedAt) && !isNil(element.certificationStatus)
            && (element.certificationStatus === 'C' ||
              !isNil(element.CertificationNumber))) {
            let event: any = {};
            event.type = 'Vehicle Certified';

            if (!isNil(element.certification) &&
              !isNil(element.certification.forceCertificationFlag) &&
              element.certification.forceCertificationFlag === 'true') {
              event.value = '<b>Certification Type</b>: Force Certified';
            } else {
              event.value = '<b>Certification Type</b>: Regular Certified';
            }

            if (element.certificationNumber !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Certification Number</b>: '
                + element.certificationNumber;
            }

            if (!isNil(element.certification) &&
              element.certification.certificationDate !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Certification Date</b>: ' +
                new Date(element.certification.certificationDate).
                  toLocaleDateString() + ' ' +
                new Date(element.certification.certificationDate).
                  toLocaleTimeString();
            }

            if (!isNil(element.certification) &&
              element.certification.planAmount !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Plan Amount</b>: ' +
                element.certification.planAmount;
            }

            if (!isNil(element.certification) &&
              element.certification.warrantyCode !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Warranty Code</b>: ' +
                element.certification.warrantyCode;
            }

            if (!isNil(element.certification) &&
              element.certification.warrantyExpiryDate !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Warranty Expiry Date</b>: '
                + new Date(element.certification.warrantyExpiryDate).
                  toLocaleDateString() + ' ' +
                new Date(element.certification.warrantyExpiryDate).
                  toLocaleTimeString();
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }

            if (element.isAllowOpenCampaign !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
              else
                event.value = '<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
            }

            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.updatedAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.updatedAt) && !isNil(element.certificationStatus)
            && element.certificationStatus === 'Z'
            && element.inventoryStatus !== 'D') {
            let event: any = {};
            event.type = 'Vehicle Decertified';
            if (element.deCertifyReason !== undefined) {
              const isTbdPresent = element.deCertifyReason?.toLowerCase().includes('tbd') ? true : false;
              let result = '';
              if(isTbdPresent){
                let words = element.deCertifyReason.split(' ');
                let filteredWords = words.filter(word => word.toLowerCase() !== 'tbd');
                result = filteredWords.join(' ');
              }
              const handleMsg = isTbdPresent ? result : element.deCertifyReason;
              event.value = '<b>Decertify Reason</b>: ' + handleMsg;
            }
            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }
            if (element.mileage !== undefined) {
              event.value = event.value + ',&nbsp;&nbsp;<b>Mileage</b>: '
                + element.mileage;
            }
            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.updatedAt) && !isNil(element.inventoryStatus)
            && element.inventoryStatus === 'D' &&
            isNil(element.tradeStatus) && isNil(element.rdrDetail)) {
            let event: any = {};
            event.type = 'Vehicle Deleted';
            if (element.planAmount !== undefined) {
              event.value = '<b>Plan Amount</b>: ' + element.planAmount;
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }

            if (element.isAllowOpenCampaign !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
              else
                event.value = '<b>Allow Open Campaign</b>: '
                  + element.isAllowOpenCampaign;
            }

            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.updatedAt) && !isNil(element.inventoryStatus)
            && element.inventoryStatus === 'S' && isNil(element.rdrDetail)) {
            let event: any = {};
            event.type = 'Vehicle InStock';
            if (element.planAmount !== undefined) {
              event.value = '<b>Plan Amount</b>: ' + element.planAmount;
            }
            if (element.certificationNumber !== undefined) {
              event.value = '<b>Certification Number</b>: ' +
                element.certificationNumber;
            }
            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.rdrDetail)
            && element.rdrDetail.rdrStatus === 'H') {
            let event: any = {};
            event.type = 'RDR Processed';
            if (element.vinDealer !== undefined) {
              event.value = 'Vin Dealer: ' + element.vinDealer;
            }

            if (element.rdrDetail.financeInsuranceManagerId !== undefined) {
              if (event.value !== undefined)
                event.value = event.value +
                  ',&nbsp;&nbsp;<b>Finance Insurance Manager</b>: ' +
                  element.rdrDetail.financeInsuranceManagerId;
              else
                event.value = '<b>Finance Insurance Manager</b>: '
                  + element.rdrDetail.financeInsuranceManagerId;
            }

            if (element.rdrDetail.salesPersonId !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Sale Person Id</b>: '
                  + element.rdrDetail.salesPersonId;
              else
                event.value = '<b>Sale Person Id</b>: '
                  + element.rdrDetail.salesPersonId;
            }
            if (element?.rdrDetail?.retailDate) {             
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Sale Date</b>: ' +
                  datepipe.transform(element?.rdrDetail?.retailDate, 'MM/dd/yyyy');
              else
                event.value = '<b>Sale Date</b>: ' +
                  datepipe.transform(element?.rdrDetail?.retailDate, 'MM/dd/yyyy');
            }

            if (element.rdrDetail.mileage !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Mileage</b>: '
                  + element.rdrDetail.mileage;
              else
                event.value = '<b>Mileage</b>: ' + element.rdrDetail.mileage;
            }

            if (element.retailAmount !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Retail Amount</b>: '
                  + element.retailAmount;
              else
                event.value = '<b>Retail Amount</b>: ' + element.retailAmount;
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }

            if (element.rdrDetail.comment !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments for RDR</b>: '
                  + element.rdrDetail.comment;
              else
                event.value = '<b>Comments for RDR</b>: ' + element.rdrDetail.comment;
            }

            if (element.rdrDetail.isAllowOpenCampaign !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Allow Open Campaign</b>: '
                  + element.rdrDetail.isAllowOpenCampaign;
              else
                event.value = '<b>Allow Open Campaign</b>: '
                  + element.rdrDetail.isAllowOpenCampaign;
            }

            if (!isNil(element.rdrDetail.updatedAt)) {
              event.userId = 'Batch Process';
              event.date = element.rdrDetail.updatedAt;
            } else if (!isNil(element.rdrDetail.createdAt)) {
              event.userId = 'Batch Process';
              event.date = element.rdrDetail.createdAt;
            } else if (!isNil(element.updatedAt)) {
              event.userId = 'Batch Process';
              event.date = element.updatedAt;
            } else {
              event.userId = 'Batch Process';
              event.date = element.createdAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.rdrDetail) &&
            element.rdrDetail.rdrStatus === 'R') {
            let event: any = {};
            event.type = 'RDR In Progress';
            if (element.vinDealer !== undefined) {
              event.value = 'Vin Dealer: ' + element.vinDealer;
            }

            if (element.rdrDetail.financeInsuranceManagerId !== undefined) {
              if (event.value !== undefined)
                event.value = event.value +
                  ',&nbsp;&nbsp;<b>Finance Insurance Manager</b>: ' +
                  element.rdrDetail.financeInsuranceManagerId;
              else
                event.value = '<b>Finance Insurance Manager</b>: '
                  + element.rdrDetail.financeInsuranceManagerId;
            }

            if (element.rdrDetail.salesPersonId !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Sale Person Id</b>: '
                  + element.rdrDetail.salesPersonId;
              else
                event.value = '<b>Sale Person Id</b>: ' +
                  element.rdrDetail.salesPersonId;
            }
            if (element?.rdrDetail?.retailDate) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Sale Date</b>: ' +
                  datepipe.transform(element?.rdrDetail?.retailDate, 'MM/dd/yyyy');
              else
                event.value = '<b>Sale Date</b>: ' +
                datepipe.transform(element?.rdrDetail?.retailDate, 'MM/dd/yyyy');
            }

            if (element.rdrDetail.mileage !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Mileage</b>: '
                  + element.rdrDetail.mileage;
              else
                event.value = '<b>Mileage</b>: ' + element.rdrDetail.mileage;
            }

            if (element.retailAmount !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Retail Amount</b>: '
                  + element.retailAmount;
              else
                event.value = '<b>Retail Amount</b>: ' + element.retailAmount;
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }

            if (element.rdrDetail.comment !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments for RDR</b>: '
                  + element.rdrDetail.comment;
              else
                event.value = '<b>Comments</b>: ' + element.rdrDetail.comment;
            }

            if (element.rdrDetail.isAllowOpenCampaign !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Allow Open Campaign</b>: '
                  + element.rdrDetail.isAllowOpenCampaign;
              else
                event.value = '<b>Allow Open Campaign</b>: '
                  + element.rdrDetail.isAllowOpenCampaign;
            }

            if (!isNil(element.rdrDetail.buyer)) {

              if (!isNil(element.rdrDetail.buyer.firstName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>First Name</b>: '
                    + element.rdrDetail.buyer.firstName;
                else
                  event.value = '<b>First Name</b>: ' +
                    element.rdrDetail.buyer.firstName;
              }

              if (!isNil(element.rdrDetail.buyer.lastName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Last Name</b>: '
                    + element.rdrDetail.buyer.lastName;
                else
                  event.value = '<b>Last Name</b>: ' +
                    element.rdrDetail.buyer.lastName;
              }

              if (!isNil(element.rdrDetail.buyer.addressLineOne)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Address1</b>: '
                    + element.rdrDetail.buyer.addressLineOne;
                else
                  event.value = '<b>Address1</b>: ' +
                    element.rdrDetail.buyer.addressLineOne;
              }

              if (!isNil(element.rdrDetail.buyer.addressLineTwo)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Address2</b>: '
                    + element.rdrDetail.buyer.addressLineTwo;
                else
                  event.value = '<b>Address2</b>: ' +
                    element.rdrDetail.buyer.addressLineTwo;
              }

              if (!isNil(element.rdrDetail.buyer.city)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>City</b>: '
                    + element.rdrDetail.buyer.city;
                else
                  event.value = '<b>City</b>: ' +
                    element.rdrDetail.buyer.city;
              }

              if (!isNil(element.rdrDetail.buyer.state)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>State</b>: '
                    + element.rdrDetail.buyer.state;
                else
                  event.value = '<b>State</b>: ' +
                    element.rdrDetail.buyer.state;
              }

              if (!isNil(element.rdrDetail.buyer.zipCode)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Zip Code</b>: '
                    + element.rdrDetail.buyer.zipCode;
                else
                  event.value = '<b>Zip Code</b>: ' +
                    element.rdrDetail.buyer.zipCode;
              }

              if (!isNil(element.rdrDetail.buyer.homePhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Home Phone</b>: '
                    + element.rdrDetail.buyer.homePhone;
                else
                  event.value = '<b>Home Phone</b>: ' +
                    element.rdrDetail.buyer.homePhone;
              }

              if (!isNil(element.rdrDetail.buyer.workPhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Work Phone</b>: '
                    + element.rdrDetail.buyer.workPhone;
                else
                  event.value = '<b>Work Phone</b>: ' +
                    element.rdrDetail.buyer.workPhone;
              }

              if (!isNil(element.rdrDetail.buyer.email)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Email</b>: '
                    + element.rdrDetail.buyer.email;
                else
                  event.value = '<b>Email</b>: ' +
                    element.rdrDetail.buyer.email;
              }
            }

            if (!isNil(element.rdrDetail.cobuyer)) {

              if (!isNil(element.rdrDetail.cobuyer.firstName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co First Name</b>: '
                    + element.rdrDetail.cobuyer.firstName;
                else
                  event.value = '<b>Co First Name</b>: ' +
                    element.rdrDetail.cobuyer.firstName;
              }

              if (!isNil(element.rdrDetail.cobuyer.lastName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Last Name</b>: '
                    + element.rdrDetail.cobuyer.lastName;
                else
                  event.value = '<b>Co Last Name</b>: ' +
                    element.rdrDetail.cobuyer.lastName;
              }

              if (!isNil(element.rdrDetail.cobuyer.addressLineOne)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Address1</b>: '
                    + element.rdrDetail.cobuyer.addressLineOne;
                else
                  event.value = '<b>Co Address1</b>: ' +
                    element.rdrDetail.cobuyer.addressLineOne;
              }

              if (!isNil(element.rdrDetail.cobuyer.addressLineTwo)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Address2</b>: '
                    + element.rdrDetail.cobuyer.addressLineTwo;
                else
                  event.value = '<b>Co Address2</b>: ' +
                    element.rdrDetail.cobuyer.addressLineTwo;
              }

              if (!isNil(element.rdrDetail.cobuyer.city)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co City</b>: '
                    + element.rdrDetail.cobuyer.city;
                else
                  event.value = '<b>Co City</b>: ' +
                    element.rdrDetail.cobuyer.city;
              }

              if (!isNil(element.rdrDetail.cobuyer.state)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co State</b>: '
                    + element.rdrDetail.cobuyer.state;
                else
                  event.value = '<b>Co State</b>: ' +
                    element.rdrDetail.cobuyer.state;
              }

              if (!isNil(element.rdrDetail.cobuyer.zipCode)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Zip Code</b>: '
                    + element.rdrDetail.cobuyer.zipCode;
                else
                  event.value = '<b>Co Zip Code</b>: ' +
                    element.rdrDetail.cobuyer.zipCode;
              }

              if (!isNil(element.rdrDetail.cobuyer.homePhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Home Phone</b>: '
                    + element.rdrDetail.cobuyer.homePhone;
                else
                  event.value = '<b>Co Home Phone</b>: ' +
                    element.rdrDetail.cobuyer.homePhone;
              }

              if (!isNil(element.rdrDetail.cobuyer.workPhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Work Phone</b>: '
                    + element.rdrDetail.cobuyer.workPhone;
                else
                  event.value = '<b>Co Work Phone</b>: ' +
                    element.rdrDetail.cobuyer.workPhone;
              }

              if (!isNil(element.rdrDetail.cobuyer.email)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Email</b>: '
                    + element.rdrDetail.cobuyer.email;
                else
                  event.value = '<b>Co Email</b>: ' +
                    element.rdrDetail.cobuyer.email;
              }
            }
            if (!isNil(element.rdrDetail.createdBy)) {
              event.userId = element.rdrDetail.createdBy;
              event.date = element.rdrDetail.createdAt;
            } else if (!isNil(element.rdrDetail.updatedBy)) {
              event.userId = element.rdrDetail.updatedBy;
              event.date = element.rdrDetail.updatedAt;
            } else if (!isNil(element.updatedBy)) {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            } else {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.rdrDetail) &&
            isNil(element.rdrDetail.rdrStatus)) {
            let event: any = {};
            event.type = 'RDR Updated';
            if (element.vinDealer !== undefined) {
              event.value = 'Vin Dealer: ' + element.vinDealer;
            }

            if (element.rdrDetail.financeInsuranceManagerId !== undefined) {
              if (event.value !== undefined)
                event.value = event.value +
                  ',&nbsp;&nbsp;<b>Finance Insurance Manager</b>: ' +
                  element.rdrDetail.financeInsuranceManagerId;
              else
                event.value = '<b>Finance Insurance Manager</b>: '
                  + element.rdrDetail.financeInsuranceManagerId;
            }

            if (element.rdrDetail.salesPersonId !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Sale Person Id</b>: '
                  + element.rdrDetail.salesPersonId;
              else
                event.value = '<b>Sale Person Id</b>: ' +
                  element.rdrDetail.salesPersonId;
            }

            if (element?.rdrDetail?.retailDate) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Sale Date</b>: ' +
                  datepipe.transform(element?.rdrDetail?.retailDate, 'MM/dd/yyyy');
              else
                event.value = '<b>Sale Date</b>: ' +
                datepipe.transform(element?.rdrDetail?.retailDate, 'MM/dd/yyyy');
            }

            if (element.rdrDetail.mileage !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Mileage</b>: '
                  + element.rdrDetail.mileage;
              else
                event.value = '<b>Mileage</b>: ' + element.rdrDetail.mileage;
            }

            if (element.retailAmount !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Retail Amount</b>: '
                  + element.retailAmount;
              else
                event.value = '<b>Retail Amount</b>: ' + element.retailAmount;
            }

            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }

            if (!isNil(element.rdrDetail.buyer)) {

              if (!isNil(element.rdrDetail.buyer.firstName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>First Name</b>: '
                    + element.rdrDetail.buyer.firstName;
                else
                  event.value = '<b>First Name</b>: ' +
                    element.rdrDetail.buyer.firstName;
              }

              if (!isNil(element.rdrDetail.buyer.lastName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Last Name</b>: '
                    + element.rdrDetail.buyer.lastName;
                else
                  event.value = '<b>Last Name</b>: ' +
                    element.rdrDetail.buyer.lastName;
              }

              if (!isNil(element.rdrDetail.buyer.addressLineOne)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Address1</b>: '
                    + element.rdrDetail.buyer.addressLineOne;
                else
                  event.value = '<b>Address1</b>: ' +
                    element.rdrDetail.buyer.addressLineOne;
              }

              if (!isNil(element.rdrDetail.buyer.addressLineTwo)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Address2</b>: '
                    + element.rdrDetail.buyer.addressLineTwo;
                else
                  event.value = '<b>Address2</b>: ' +
                    element.rdrDetail.buyer.addressLineTwo;
              }

              if (!isNil(element.rdrDetail.buyer.city)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>City</b>: '
                    + element.rdrDetail.buyer.city;
                else
                  event.value = '<b>City</b>: ' +
                    element.rdrDetail.buyer.city;
              }

              if (!isNil(element.rdrDetail.buyer.state)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>State</b>: '
                    + element.rdrDetail.buyer.state;
                else
                  event.value = '<b>State</b>: ' +
                    element.rdrDetail.buyer.state;
              }

              if (!isNil(element.rdrDetail.buyer.zipCode)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Zip Code</b>: '
                    + element.rdrDetail.buyer.zipCode;
                else
                  event.value = '<b>Zip Code</b>: ' +
                    element.rdrDetail.buyer.zipCode;
              }

              if (!isNil(element.rdrDetail.buyer.homePhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Home Phone</b>: '
                    + element.rdrDetail.buyer.homePhone;
                else
                  event.value = '<b>Home Phone</b>: ' +
                    element.rdrDetail.buyer.homePhone;
              }

              if (!isNil(element.rdrDetail.buyer.workPhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Work Phone</b>: '
                    + element.rdrDetail.buyer.workPhone;
                else
                  event.value = '<b>Work Phone</b>: ' +
                    element.rdrDetail.buyer.workPhone;
              }

              if (!isNil(element.rdrDetail.buyer.email)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Email</b>: '
                    + element.rdrDetail.buyer.email;
                else
                  event.value = '<b>Email</b>: ' +
                    element.rdrDetail.buyer.email;
              }
            }

            if (!isNil(element.rdrDetail.cobuyer)) {

              if (!isNil(element.rdrDetail.cobuyer.firstName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co First Name</b>: '
                    + element.rdrDetail.cobuyer.firstName;
                else
                  event.value = '<b>Co First Name</b>: ' +
                    element.rdrDetail.cobuyer.firstName;
              }

              if (!isNil(element.rdrDetail.cobuyer.lastName)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Last Name</b>: '
                    + element.rdrDetail.cobuyer.lastName;
                else
                  event.value = '<b>Co Last Name</b>: ' +
                    element.rdrDetail.cobuyer.lastName;
              }

              if (!isNil(element.rdrDetail.cobuyer.addressLineOne)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Address1</b>: '
                    + element.rdrDetail.cobuyer.addressLineOne;
                else
                  event.value = '<b>Co Address1</b>: ' +
                    element.rdrDetail.cobuyer.addressLineOne;
              }

              if (!isNil(element.rdrDetail.cobuyer.addressLineTwo)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Address2</b>: '
                    + element.rdrDetail.cobuyer.addressLineTwo;
                else
                  event.value = '<b>Co Address2</b>: ' +
                    element.rdrDetail.cobuyer.addressLineTwo;
              }

              if (!isNil(element.rdrDetail.cobuyer.city)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co City</b>: '
                    + element.rdrDetail.cobuyer.city;
                else
                  event.value = '<b>Co City</b>: ' +
                    element.rdrDetail.cobuyer.city;
              }

              if (!isNil(element.rdrDetail.cobuyer.state)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co State</b>: '
                    + element.rdrDetail.cobuyer.state;
                else
                  event.value = '<b>Co State</b>: ' +
                    element.rdrDetail.cobuyer.state;
              }

              if (!isNil(element.rdrDetail.cobuyer.zipCode)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Zip Code</b>: '
                    + element.rdrDetail.cobuyer.zipCode;
                else
                  event.value = '<b>Co Zip Code</b>: ' +
                    element.rdrDetail.cobuyer.zipCode;
              }

              if (!isNil(element.rdrDetail.cobuyer.homePhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Home Phone</b>: '
                    + element.rdrDetail.cobuyer.homePhone;
                else
                  event.value = '<b>Co Home Phone</b>: ' +
                    element.rdrDetail.cobuyer.homePhone;
              }

              if (!isNil(element.rdrDetail.cobuyer.workPhone)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Work Phone</b>: '
                    + element.rdrDetail.cobuyer.workPhone;
                else
                  event.value = '<b>Co Work Phone</b>: ' +
                    element.rdrDetail.cobuyer.workPhone;
              }

              if (!isNil(element.rdrDetail.cobuyer.email)) {
                if (event.value !== undefined)
                  event.value = event.value
                    + ',&nbsp;&nbsp;<b>Co Email</b>: '
                    + element.rdrDetail.cobuyer.email;
                else
                  event.value = '<b>Co Email</b>: ' +
                    element.rdrDetail.cobuyer.email;
              }
            }

            if (!isNil(element.rdrDetail.createdBy)) {
              event.userId = element.rdrDetail.createdBy;
              event.date = element.rdrDetail.createdAt;
            } else if (!isNil(element.rdrDetail.updatedBy)) {
              event.userId = element.rdrDetail.updatedBy;
              event.date = element.rdrDetail.updatedAt;
            } else if (!isNil(element.updatedBy)) {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            } else {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.rdrDetail)
            && element.rdrDetail.rdrStatus === 'V') {
            let event: any = {};
            event.type = 'RDR Reversed';
            if (element.vinDealer !== undefined) {
              event.value = 'Vin Dealer: ' + element.vinDealer;
            }
            if (element.rdrDetail.reversalReason !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Reversal Reason</b>: '
                  + element.rdrDetail.reversalReason;
              else
                event.value = '<b>Reversal Reason</b>: '
                  + element.rdrDetail.reversalReason;
            }

            if (element.rdrDetail.reversalType !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Reversal Type</b>: '
                  + getReversalTypeDesc(element.rdrDetail.reversalType);
              else
                event.value = '<b>Reversal Type</b>: ' +
                  getReversalTypeDesc(element.rdrDetail.reversalType);
            }

            if (!isNil(element.rdrDetail.updatedBy)) {
              event.userId = element.rdrDetail.updatedBy;
              event.date = element.rdrDetail.updatedAt;
            } else if (!isNil(element.rdrDetail.createdBy)) {
              event.userId = element.rdrDetail.createdBy;
              event.date = element.rdrDetail.createdAt;
            } else if (!isNil(element.updatedBy)) {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            } else {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.updatedAt) && !isNil(element.tradeStatus)
            && element.tradeStatus === 'T') {
            let event: any = {};
            event.type = 'Vehicle Traded';
            if (element.vinDealer !== undefined) {
              event.value = 'Vin Dealer: ' + element.vinDealer;
            }
            if (element.comments !== undefined) {
              if (event.value !== undefined)
                event.value = event.value + ',&nbsp;&nbsp;<b>Comments</b>: '
                  + element.comments;
              else
                event.value = '<b>Comments</b>: ' + element.comments;
            }
            if (isNil(element.updatedBy)) {
              event.userId = element.createdBy;
              event.date = element.createdAt;
            } else {
              event.userId = element.updatedBy;
              event.date = element.updatedAt;
            }
            eventsData.push(event);
          } else if (!isNil(element.vehicleDecertification)) {
            let event: any = {};
            event.type = 'Vehicle Decertification';
            event.date = element.vehicleDecertification.LAST_UPDATE_TIMESTAMP;
            event.userId = element.vehicleDecertification?.LAST_UPDATED_ID;
            if (!isNil(element.vehicleDecertification.BLOCK_CERTIFICATION_FLAG) &&
              element.vehicleDecertification.BLOCK_CERTIFICATION_FLAG === 'Y') {
              event.value = '<b>Type</b>: Blocked' + ',&nbsp;&nbsp;<b>Reason</b>: '
                + element.vehicleDecertification.DECERTIFICATION_REASON_CODE
                + ',&nbsp;&nbsp;<b>Source</b>: '
                + element.vehicleDecertification.DECERTIFICATION_SOURCE_CODE
                + ',&nbsp;&nbsp;<b>Comment</b>: '
                + element.vehicleDecertification.DECERTIFICATION_COMMENTS;
            } else {
              event.value = '<b>Type</b>: Un Blocked'
                + ',&nbsp;&nbsp;<b>Comment</b>: '
                + element.vehicleDecertification.UNBLOCK_COMMENT;
            }
            eventsData.push(event);
          }
        });
        historyEvent.events = eventsData;
      }
      historyData.push(historyEvent);
    });

    // historyEvent.events = eventsData;
    vehicleHistoryData.vehicleHistory = historyData;
  }
  return vehicleHistoryData;
}

function getReversalTypeDesc(type: string): string {

  let reversalDesc: any;

  let reversalCodeitems: Array<any> =
    [
      {
        name: '21 - REPORTED A WRONG VEHICLE', desc: 'REPORTED A WRONG VEHICLE',
        value: '21', disabled: false
      },
      {
        name: '22 - CUSTOMER RETURNED VEHICLE', desc: 'CUSTOMER RETURNED VEHICLE',
        value: '22', disabled: false
      },
      {
        name: '99 - TERMINATE AN AGREEMENT', desc: 'TERMINATE AN AGREEMENT',
        value: '99', disabled: true
      },
      { name: '23 - OTHERS', value: '23', desc: 'OTHERS', disabled: true },
    ];

  reversalDesc = reversalCodeitems.filter(dd => dd.value === type).map(dd => dd.desc);

  return reversalDesc;
}

