<div fxLayout="column">
    <!-- (selctedRegionForRegionaDistrictWiseEvent)="getRegionForFilter($event)" -->
    <vehicle-certification-report-search-toolbar fxFlex="100" (filterDateEvent)="reportLoad($event)" 
         [enteredvin]="enteredvin" [enteredDealerCode]="enteredDealerCode" [regionSelection]="regionSelection" [districtSelection]="districtSelection" [isRegionalAndDistrictWiseFilterSelected]="true"></vehicle-certification-report-search-toolbar>

        <uc-region-dashboard-menubar-panel (clearAllButtonEvent)="clearAllFilters();"
        [showClearAllButton]="showClearAllButton" [totalInventoriesCount]="totalInventoriesCount" [showTotalRecords]="true"
        (exportPdfEvent)="downLoadPdf()" (exportExcelEvent)="downLoadXlsx()">
      </uc-region-dashboard-menubar-panel>

    <uc-regional-and-district-sales-cmp-grid [columnDefs]="columnDefs" [columnDefsTotal]="columnDefsTotal" [dealerTotal]="dealerTotal"
        [rows]="rows" (applySortEvent)="applyInventorySort($event)" (applyFilterEvent)="ApplyFilter($event)"
        [clearSort]="clearSort" [totalSaleCount]="totalSaleCount" [totalGridSaleCount]="totalGridSaleCount" (countIncreaseEvent)="countIncreaseEvent($event)"
        (countDecreaseEvent)="countDecreaseEvent($event)" (sendRegionSelection)="getRegionForFilter($event)">
    </uc-regional-and-district-sales-cmp-grid>
</div>

