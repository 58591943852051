import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

// Array of new field names
const excludedFieldsForTotalGrid = ['regionCode', 'districtCode',];
const excludedFieldsForGrid = ['regionCode', 'currentMonthRegionObjective'];

export class InventorySummaryGridColumns {
  // district wise columns
  static allGridColumns = [
     {// Region Name
      key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
     {// DEALER_REGION_NUM_COLUMN
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'regionCode', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
     {// DISTRICT_NAME_COLUMN
      key: ViewConstants.DISTRICT_NAME_COLUMN, fieldName: 'districtCode', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '125',
      columnMinWidth: '125', columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    { // TCUV_Gold_Daily_Sales_Units
      key: ViewConstants.TCUV_Gold_Daily_Sales_Units, fieldName: 'dailyGoldSales', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '125',
      columnMinWidth: '125', columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.STARTS_WITH
    },
    { //TCUV_Silver_Daily_Sales_Units
      key: ViewConstants.TCUV_Silver_Daily_Sales_Units, fieldName: 'dailySilverSales',
      style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '125', columnMinWidth: '125',
      columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_4_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Total_TCUV_Daily_Sales_Unit
      key: ViewConstants.Total_TCUV_Daily_Sales_Unit, fieldName: 'dailyGoldPlusSilverSales', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '125',
      columnMinWidth: '125', columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// currentMonthRegionObjective
      key: ViewConstants.CurrentMonthRegionObjective,
      fieldName: 'currentMonthRegionObjective', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Total_TCUV_PY_Same_Business_Day
      key: ViewConstants.Total_TCUV_PY_Same_Business_Day, fieldName: 'prevYrSameDaySales', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '210',
      columnMinWidth: '210', columnMaxWidth: '210', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    {// TCUV_Gold_MTD_Sales_Units
      key: ViewConstants.TCUV_Gold_MTD_Sales_Units, fieldName: 'monthlyGoldSales', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '125', columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// TUCV_Silver_MTD_Sales_Unit
      key: ViewConstants.TUCV_Silver_MTD_Sales_Unit, fieldName: 'monthlySilverSales',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '125', columnMinWidth: '125',
      columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Total_TCUV_MTD_Sales_Unit
      key: ViewConstants.Total_TCUV_MTD_Sales_Unit, fieldName: 'monthlyGoldPlusSilverSales', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    // {// Ranked_Amongst_Nation_MTD
    //   key: ViewConstants.Ranked_Amongst_Nation_MTD, fieldName: 'rdrDetail.buyer.firstName',
    //   style: '', hasTemplate: 'true',
    //   sortable: 'true', template: '', columnWidth: '125', columnMinWidth: '125',
    //   columnMaxWidth: '125', currencyField: 'false', filterValue: '',
    //   dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
    //   filterType: FilterType.CONTAINS
    // },
    {// Total_TCUV_PY_MTD_Sale
      key: ViewConstants.Total_TCUV_PY_MTD_Sale, fieldName: 'prevYrSameMonthSales',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '210', columnMinWidth: '210',
      columnMaxWidth: '210', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    {// TCUV_Gold_YTD_Sales_Units
      key: ViewConstants.TCUV_Gold_YTD_Sales_Units, fieldName: 'yearlyGoldSales',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// TCUV_Silver_YTD_Sales_Units
      key: ViewConstants.TCUV_Silver_YTD_Sales_Units, fieldName: 'yearlySilverSales',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '125',
      columnMinWidth: '125', columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    { // Total_TCUV_YTD_Sales_Units
      key: ViewConstants.Total_TCUV_YTD_Sales_Units, fieldName: 'totalYearlySilverGoldSales', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '125', columnMinWidth: '125',
      columnMaxWidth: '125', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    // { // Ranked_Amongst_Nation_YTD
    //   key: ViewConstants.Ranked_Amongst_Nation_YTD,
    //   fieldName: 'repairOrder.repairOrderNumber1', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },
    {// Total_TCUV_PY_YTD_Sales
      key: ViewConstants.Total_TCUV_PY_YTD_Sales,
      fieldName: 'prevYrTotalSales', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '220', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    {// Day_Supply_Inv_less_than_60_days
      key: ViewConstants.Day_Supply_Inv_less_than_60_days,
      fieldName: 'daySupplyInvLessThan60', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Turn_Rate_Inv_Less_than_60_days
      key: ViewConstants.Turn_Rate_Inv_Less_than_60_days,
      fieldName: 'turnRateInvLessThan60', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '130', columnMinWidth: '130',
      columnMaxWidth: '130', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Day_Supply_Total_Inventory
      key: ViewConstants.Day_Supply_Total_Inventory,
      fieldName: 'daySupplyFullInv', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Turn_Rate_Total_Inventory
      key: ViewConstants.Turn_Rate_Total_Inventory,
      fieldName: 'turnRateFullInv', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Cert_to_Sale_Date_Avg_MTD
      key: ViewConstants.Cert_to_Sale_Date_Avg_MTD,
      fieldName: 'certToSaleAvgMTD', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Cert_to_Sale_Date_Avg_YTD
      key: ViewConstants.Cert_to_Sale_Date_Avg_YTD,
      fieldName: 'certToSaleAvgYTD', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Acq_to_Sale_date_Avg_MTD
      key: ViewConstants.Acq_to_Sale_date_Avg_MTD,
      fieldName: 'acqToSaleAvgMTD', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Acq_to_Sale_date_Avg_YTD
      key: ViewConstants.Acq_to_Sale_date_Avg_YTD,
      fieldName: 'acqToSaleAvgYTD', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// OnHand_InvLess_than_60days
      key: ViewConstants.OnHand_InvLess_than_60days,
      fieldName: 'invLessThan60Days', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Prev_MonthOnHand_InvLess_than_60days
      key: ViewConstants.Prev_MonthOnHand_InvLess_than_60days,
      fieldName: 'previousMonthSameDayOnHandLessThan60Days', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    {// Prev_YearOnHand_InvLess_than_60days
      key: ViewConstants.Prev_YearOnHand_InvLess_than_60days,
      fieldName: 'previousYrSameDayOnHandLessThan60Days', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    // {// GOLD_INVENTORY_COUNT
    //   key: ViewConstants.GOLD_INVENTORY_COUNT,
    //   fieldName: 'onHandfullInv', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },
    // {// SILVER_INVENTORY_COUNT
    //   key: ViewConstants.SILVER_INVENTORY_COUNT,
    //   fieldName: 'onHandfullInv', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },
    {// On_Hand_Full_Inventory
      key: ViewConstants.On_Hand_Full_Inventory,
      fieldName: 'onHandfullInv', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Previous_Yr_SameDayOnHand
      key: ViewConstants.Previous_Yr_SameDayOnHand,
      fieldName: 'previousYrSameDayOnHandFullInv', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    {// Previous_MonthOnHand
      key: ViewConstants.Previous_MonthOnHand,
      fieldName: 'previousMonthSameDayOnHandFullInv', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS, valueRepresentation: 'percent'
    },
    // {// DaySupplyFullInv
    //   key: ViewConstants.DaySupplyFullInv,
    //   fieldName: 'daySupplyFullInv', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },    
    
    
    // {// TurnRate
    //   key: ViewConstants.TurnRate,
    //   fieldName: 'turnRate', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },    
    // {// daySupply
    //   key: ViewConstants.DaySupply,
    //   fieldName: 'daySupply', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },
    // {// DaySupplyFullInv
    //   key: ViewConstants.DaySupplyFullInv,
    //   fieldName: 'daySupplyFullInv', style: ' ',
    //   hasTemplate: 'false',
    //   template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
    //   columnMaxWidth: '180', currencyField: 'false', filterValue: '',
    //   dataTypeformat: '', filterType: FilterType.CONTAINS
    // },    
  ];

  static  gridColumns = [
    ...this.allGridColumns.filter((x: any) => !excludedFieldsForGrid.includes(x.fieldName))
  ]
  
  static totalGridColumns = [
    ...this.allGridColumns.filter((x: any) => !excludedFieldsForTotalGrid.includes(x.fieldName))
];

}


