<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite">
  <mat-card *ngIf="reportType === '8m'" fxFlex="row" fxFlex="100">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="25" style="padding: 5px;margin-left: 2%">
      <label>Region Selection</label>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="region" [(ngModel)]="regionSelection" #region="ngModel"
        [disabled]="sharedDataService?.isGSTUser || sharedDataService?.isSETUser">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of regionItems" [value]="i">{{i}}</mat-option>
      </mat-select>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;margin-left: 2%">
      <label>Report Type</label>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="region" [(ngModel)]="reportSelection" #region="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of filterMethoditems" [value]="i.value">{{i.name}}</mat-option>
      </mat-select>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="25" style="padding: 5px;margin-left: 2%">
      <label>Certification Type</label>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="certification" [(ngModel)]="certificationSelection" #certification="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of certificationMethoditems" [value]="i.value">{{i.name}}</mat-option>
      </mat-select>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="15" style="padding: 5px;">
      <button [disabled]="!regionSelection || !reportSelection || !certificationSelection" (click)="filterValueChanged();" mat-button
        mat-raised-button>Apply</button>
    </div>
  </mat-card>
  <mat-card *ngIf="reportType === '9'" fxFlex="row" fxFlex="100">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="10" style="padding: 5px;margin-left: 2%">
      <label>Report Type</label>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="region" [(ngModel)]="reportSelection" #region="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of filterMethoditems" [value]="i.value">{{i.name}}</mat-option>
      </mat-select>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;">
      <label>Dealer Code</label>
      <mat-form-field style="width: 145px; height: 50px;">
        <input #dCodeTxtBox matInput [(ngModel)]="enteredDealerCode" [ddFieldValidator]="8"
          placeholder="Enter dealer code" align="end">
      </mat-form-field>

    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="15" style="padding: 5px;">
      <button mat-button mat-raised-button (click)="findDealer();">FIND DEALER</button>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="10" style="padding: 5px;">
      <button [disabled]="!reportSelection || !enteredDealerCode" (click)="filterValueChanged();"
        mat-button mat-raised-button>Apply</button>
    </div>
  </mat-card>
  <mat-card *ngIf="reportType !== '8m' && reportType !== '9'" fxFlex="row" fxFlex="50">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;margin-left: 2%">
      <label>Report Type</label>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;margin-left: 10%">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="region" [(ngModel)]="reportType" #region="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of filterMethoditems" [value]="i.value">{{i.name}}</mat-option>
      </mat-select>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">
      <button [disabled]="!reportType" (click)="filterValueChanged();" mat-button mat-raised-button>Apply</button>
    </div>
  </mat-card>

</div>
