// View Constants
export class ViewConstants {
  // Application Title
  static APPLICATION_TITLE_FOR_REPORT: string
    = 'CERTIFIED VEHICLES APPLICATION, TOYOTA MOTOR SALES U.S.A., INC.';
  // constant for Content-Type
  static CONTENT_TYPE: string = 'Content-Type';
  // constant for application/json
  static APPLICATION_JSON: string = 'application/json';
  // constant for Accept
  static ACCEPT: string = 'Accept';
  // Constants for Inventory Summary Screen Name
  static INVENTORY_SUMMARY_SCREEN_NAME = 'USED CARS INVENTORY SUMMARY';
  // Constants for Accounting Summary Screen Name
  static ACCOUNTING_SUMMARY_SCREEN_NAME = 'USED CARS ACCOUNTING SUMMARY';
  // Constants for Rdr Summary Screen Name
  static RDR_SUMMARY_SCREEN_NAME = 'USED CARS RDR SUMMARY';
  // Constants for Sale Summary Screen Name
  static SALE_SUMMARY_SCREEN_NAME = 'USED CARS SALE SUMMARY';
  // Constants for Sale Summary Screen Name
  static INVENTORY_REPORT_SUMMARY_SCREEN_NAME = 'USED CARS INVENTORY REPORT';
  // Constants for Sale Summary Screen Name
  static ADMIN_SCREEN_NAME = 'USED CARS ADMIN';
  // Constant for Vehicle Details screen name
  static VEHICLE_DETAIL_SCREEN_NAME = 'VEHICLE DETAILS';
  // Constant for Accounting Details screen name
  static ACCOUNTING_DETAIL_SCREEN_NAME = 'ACCOUNTING DETAILS';
  // Constant for Vehicle Details screen name
  static DEALER_PARTICIPANT_SCREEN_NAME = 'DEALER PARTICPANT';
  // Constant for Dealer detail popup
  static DEALER_DETAILS_SCREEN_NAME = 'DEALER DETAILS';
  // Constant to check whether the required parameter is null or not
  static REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED: string =
    'Catastrophe!! Required parameter is null or undefined while invoking {ServiceName} Services. '
    + 'Parameter Name : {ParameterName}';
  // Constant for Ascending key
  static ASC: string = 'ASC';
  // Constant for Descending key
  static DESC: string = 'DESC';

  /**
   * Column names for Inventory Summary Grid
   */
  // Column name for VIN
  static VIN = 'VIN';
  // Column name for DAYS IN STOCK
  static DIS = 'DIS';
  // Column name for MODEL
  static MODEL_NO = 'MODEL';
  static MODEL_NUMBER = 'MODEL #';
  static MODEL_SERIES = 'MODEL SERIES';
  // Column name for Model Description
  static MODEL_DESC = 'MODEL DESCRIPTION';
  // Column name for Year
  static MODEL_YEAR = 'YEAR';
  static M_YEAR = 'MODEL YEAR';
  // Column name for Accessories
  static ACCESSORIES = 'ACCESSORIES';
  // Column name for Certified
  static CERTIFIED = 'CERTIFIED';
  // Column name for Retail Price
  static RETAIL_PRICE = 'RETAIL PRICE($)';
  // Column name for ACV Price
  static ACV_PRICE = 'ACV PRICE($)';
  // Column name for ACV Price
  static SAME_DAY_CERT = 'SAME DAY CERT';  
  static SALE_MONTH = 'SALE MONTH';
  static OVERLAP = 'OVERLAP';
  static RT_VEHCILE = 'RT Vehicle';
  static SALE_TO_RDR = 'Sale Date to RDR Date';
  // Column name for Recondition Cost
  static RECONDITION_COST = 'RECONDITION COST($)';
  // Column name for Vehicle Type
  static VEHICLE_TYPE = 'VEHICLE TYPE';
  // Column name for Hybrid Indicator
  static HYBRID_INDICATOR = 'HYBRID INDC';
  // Column name for ACV Price
  static STOCK_PRICE = 'STOCK PRICE($)';
  // Column name for Sales Price
  static SALES_PRICE = 'SALES PRICE($)';
  // Column name for Plan Code
  static PLAN_CODE = 'PLAN CODE';
  // Column name for Open Campaign Status
  static OPEN_CAMPAIGN_STATUS = 'OPEN CAMPAIGN STATUS';
  // Column name for Carfax Flag
  static CARFAX_FLAG = 'CARFAX FLAG';
  // Column name for Certification Number
  static CERTIFICATION_NUMBER = 'CERTIFICATION NO';
  // Column name for Certification Number
  static FORCE_CERTIFIED = 'FORCE CERTIFIED';
  // Column name for Acquired Date
  static ACQUIRED_DATE = 'ACQUIRED DATE';
  // Column name for Mileage
  static MILEAGE = 'MILEAGE';
  // Column name for Mileage
  static SALE_MILEAGE = 'SALE MILEAGE';
  // Column name for Mileage
  static SALE_CODE = 'SALE CODE';
  // Column name for Date Of First Use
  static DOFU = 'DOFU';
  // Column name for Certification Date
  static CERTIFICATION_DATE = 'CERTIFICATION DATE';
  // Column name for Comments
  static COMMENTS = 'COMMENTS';
  // Column name for Open Campaign Status
  static DE_CERTIFY_REASON = 'DE-CERTIFY REASON';
  // Column name for Exterior Color
  static EXT_COLOR = 'EXT. COLOR';
  // Column name for Interior Color
  static INT_COLOR = 'INT. COLOR';

  // Column name for Warranty Code
  static WARRANTY_CODE = 'WARRANTY CODE';

  // Column name for Warranty Code
  static WARRANTY = 'WARRANTY';

  static WARRANTY_EXP = 'WARRANTY EXPIRATION';
  static WARRANTY_CONTRACT_NUM = 'WARRANTY CONTRACT NUMBER';
  static FORM_NUMBER = 'FORM NUMBER';
  // Column name for Certification Date
  static RDR_DATE = 'RDR DATE';
  // Column name for Certification Date
  static REVERSAL_DATE = 'REVERSAL DATE';

  // static CERTIFICATION_DATE = 'CERTIFICATION DATE';
  static DE_CERTIFICATION_DATE = 'DE CERTIFICATION DATE';
  static DISTRICT = 'DISTRICT';
  static CERTIFICATON_TYPE = 'CERTIFICATON TYPE';
  /**
   * Column names for Inventory Summary Grid ended
   */

  /**
   * Column names for Dealer details grid
   */
  // constant for dealer code
  static DEALER_COLUMN = 'DEALER #';
  // Constant for DEALER NAME column in Dealer settings screen
  static DEALER_NAME_COLUMN = 'DEALER NAME';

  // Constant for DEALER NAME column in Dealer settings screen
  static REGION_NAME_COLUMN = 'REGION NAME';

  // Constant for DEALER NAME column in Dealer settings screen
  static DISTRICT_NAME_COLUMN = 'DISTRICT #';

  // Constant for DEALER NAME column in Dealer settings screen
  static DISTRICT_TOTAL_COLUMN = 'DISTRICT TOTAL';


  // Constant for DEALER NAME column in Dealer settings screen
  static DEALER_TOTAL_COLUMN = 'DEALER TOTAL';

  // Constant for DEALER ADDRESS column in Dealer settings screen
  static DEALER_ADDRESS_COLUMN = 'ADDRESS';
  // Constant for DEALER CITY column in Dealer settings screen
  static DEALER_CITY_COLUMN = 'CITY';
  // Constant for DEALER STATE column in Dealer settings screen
  static DEALER_STATE_COLUMN = 'STATE';
  // Constant for DEALER ZIP column in Dealer settings screen
  static DEALER_ZIP_COLUMN = 'ZIP';
  // Constant for DEALER PHONE column in Dealer settings screen
  static DEALER_PHONE_COLUMN = 'PHONE';
  // Constant for DEALER BRAND column in Dealer settings screen
  static DEALER_BRAND_COLUMN = 'BRAND';
  // Constant for DEALER AREA/REGION NUMBER column in Dealer settings screen
  static DEALER_AREAREGION_NUM_COLUMN = 'AREA/REGION #';
  // Constant for DEALER AREA/REGION NUMBER column in Dealer settings screen
  static DEALER_REGION_NUM_COLUMN = 'REGION #';
  // Constant for DEALER AREA/REGION NAME column in Dealer settings screen
  static DEALER_AREAREGION_STRING_COLUMN = 'AREA/REGION';
  // Constant for DEALER DISTRICT NAME column in Dealer settings screen
  static DEALER_REGION_STRING_COLUMN = 'REGION';
  // Constant for DEALER DISTRICT NAME column in Dealer settings screen
  static DEALER_REGION_NAME_STRING_COLUMN = 'REGION NAME';
  static DEALER_TERMINATION_FLAG = 'DEALER TERMINATION FLAG';
  static DEALER_COUNT_STRING_COLUMN = 'COUNT';
  static DEALER_DISTRICT_COLUMN = 'DISTRICT #';
  static INV_STATUS = 'INV STATUS';
  static COMMENT = 'COMMENT';
  static CUSTOMER_NAME = 'CUSTOMER NAME';
  static ACQUIRED_TO_CERT = 'Acq to Cert';
  static CERT_TO_SALE = 'Cert to Sale';

  static GOLD_SILVER_CERTIFICATION_TIER = 'Gold/Silver Certification Tier';
  static GOLD_DAILY_CERTIFIED_SALES_COUNT = 'Daily TCUV Gold Sales';
  static SILVER_DAILY_CERTIFIED_SALES_COUNT = 'Daily TCUV Silver Sales';
  static DAILY_CERTIFIED_SALES_COUNT = 'Daily TCUV Sales';
  static GOLD_MONTHLY_CERTIFIED_SALES_COUNT = 'MTD TCUV Gold Sales';
  static SILVER_MONTHLY_CERTIFIED_SALES_COUNT = 'MTD TCUV Silver Sales';
  static CERTIFIED_SALES_COUNT = 'MTD TCUV Sales';
  static YTD_CERTIFIED_SALES_COUNT = 'YTD TCUV Sales';
  static CUSTOM_CERTIFIED_SALES_COUNT = 'Custom Certified Sales Count';
  static SILVER_CUSTOM_CERTIFIED_SALES_COUNT = 'Silver Custom Certified Sales Count';
  static GOLD_CUSTOM_CERTIFIED_SALES_COUNT = 'Gold Custom Certified Sales Count';

  static SILVER_YTD_CERTIFIED_SALES_COUNT = 'Silver YTD Certified Sales Count';
  static GOLD_YTD_CERTIFIED_SALES_COUNT = 'Gold YTD Certified Sales Count';

  static DAILY_CERTIFIED_INVENTORY = 'Daily TCUV Certifications';
  static GOLD_DAILY_CERTIFIED_INVENTORY = 'Daily TCUV Gold Certifications';
  static SILVER_DAILY_CERTIFIED_INVENTORY = 'Daily TCUV Silver Certifications';
  static MONTHLY_CERTIFIED_INVENTORY = 'MTD TCUV Certifications';
  static GOLD_MONTHLY_CERTIFIED_INVENTORY = 'MTD TCUV Gold Certifications';
  static YTD_CERTIFIED_INVENTORY = 'YTD Certified Inventory';
  static GOLD_YTD_CERTIFIED_INVENTORY = 'Gold YTD Certified Inventory';
  static SILVER_YTD_CERTIFIED_INVENTORY = 'Silver YTD Certified Inventory';

  static CUSTOM_CERTIFIED_INVENTORY = 'Custom Certified Inventory';
  static GOLD_CUSTOM_CERTIFIED_INVENTORY = 'Gold Custom Certified Inventory';
  static SILVER_CUSTOM_CERTIFIED_INVENTORY = 'Silver Custom Certified Inventory';

  static SILVER_MONTHLY_CERTIFIED_INVENTORY = 'MTD TCUV Silver Certifications';
  static SILVER_INVENTORY_COUNT = 'TCUV Silver Inventory';
  static GOLD_INVENTORY_COUNT = 'TCUV Gold Inventory';
  static INVENTORY_COUNT = 'Total TCUV Inventory';
  static SILVER_CERTIFIED_INVENTORY = 'Silver Certified Inventory';
  static GOLD_CERTIFIED_INVENTORY = 'Gold Certified Inventory';
  static CERTIFIED_INVENTORY = 'Certified Inventory';

  static TOTAL_TCUV_OBJECTIVE= 'Current YTD TCUV Obj';
  static PREVIOUS_YTD_SALES = 'Previous YTD Sales';
  static YTD_CERTS = 'YTD Certs';
  static PREVIOUS_YTD_CERTS = 'Previous YTD Certs';
  static PREVIOUS_YTD_INV = 'Previous YTD Inventory';
  static SALES_PERCENTAGE = 'Sales %';
  static PY_CUSTOM_CERTS = 'PY Custom Certs';
  static YTV_CUSTOM_CERTS = 'YTV Custom Certs';
  static PY_CUSTOM_INV = 'PY Custom Inventory';
  static YTV_CUSTOM_INV = 'YTV Custom Inventory';
  static PY_CUSTOM_SALES = 'PY Custom Sales';
  static YTY_CUSTOM_SALES = 'YTV Custom Sales';
  static YTD_PV_CERTS = 'Certification (+/-)';
  static CUSTOM_CERT_BY_PV = 'Certification %';
  
  static CURRENT_TCUV_OBJECTIVE= 'Current Year Objective';
  static SALES_COUNT= 'Sales Count';
  static SAME_DAY_CERTS = 'Same Day Certs';
  static CERT_AFTER_SALE = 'Cert After Sale';
  static SDC = 'SDC %';
  static CAS = 'CAS %';
  static TOTAL_ = 'Total';
  static TOTAL_PERCENT = 'Total %';
  static DAYS_TO_RDR = 'Days To RDR';

 static CAMPAIGN_NUMBER = 'Campaign Number';
 
 static TCUV_Gold_Daily_Sales_Units        = 'TCUV Gold Daily Sales Units';      
 static TCUV_Silver_Daily_Sales_Units      = 'TCUV Silver Daily sales units';    
 static Total_TCUV_Daily_Sales_Unit        = 'Total TCUV - Daily sales unit';   
 static Total_TCUV_PY_Same_Business_Day    = 'Total TCUV - PY Same Business Day';
 static TCUV_Gold_MTD_Sales_Units          = 'TCUV Gold MTD Sales Units';     
 static TUCV_Silver_MTD_Sales_Unit         = 'TUCV Silver MTD Sales Unit';     
 static Total_TCUV_MTD_Sales_Unit          = 'Total TCUV MTD Sales Unit';     
 static Ranked_Amongst_Nation_MTD          = 'Ranked amongst Nation (MTD)';      
 static Total_TCUV_PY_MTD_Sale             = 'Total TCUV - PY MTD Sale';      
 static TCUV_Gold_YTD_Sales_Units          = 'TCUV Gold YTD Sales Units';      
 static TCUV_Silver_YTD_Sales_Units        = 'TCUV Silver YTD Sales Units';      
 static Total_TCUV_YTD_Sales_Units         = 'Total TCUV YTD Sales Units';     
 static Ranked_Amongst_Nation_YTD          = 'Ranked amongst Nation (YTD)';      
 static Total_TCUV_PY_YTD_Sales            = 'Total TCUV - PY YTD sales';      
 static Day_Supply_Inv_less_than_60_days   = 'Day Supply (Inv < 60 days)';      
 static Turn_Rate_Inv_Less_than_60_days    = 'Turn Rate (Inv < 60 days)';      
 static Day_Supply_Total_Inventory         = 'Day Supply (Total Inventory)';    
 static Turn_Rate_Total_Inventory          = 'Turn Rate (Total Inventory)';     
 static Cert_to_Sale_Date_Avg_MTD          = 'Cert to Sale Date Avg (MTD)';      
 static Cert_to_Sale_Date_Avg_YTD          = 'Cert to Sale Date Avg (YTD)';      
 static Acq_to_Sale_date_Avg_MTD           = 'Acq to Sale date Avg (MTD)';     
 static Acq_to_Sale_date_Avg_YTD           = 'Acq to Sale date Avg (YTD)';      
 static On_Hand_Full_Inventory             = 'on Hand - Full Inventory';      
 static Previous_Yr_SameDayOnHand          = 'Previous Yr Same Day On Hand';      
 static TurnRate                       = 'TurnRate';      
 static TurnRateFullInv                       = 'TurnRateFullInv';      
 static DaySupply                       = 'DaySupply';      
 static DaySupplyFullInv                       = 'DaySupplyFullInv';      
 static Previous_MonthOnHand           = 'Previous Month On Hand';      
 static CurrentMonthRegionObjective           = 'CurrentMonthRegionObjective';      
 static Prev_MonthOnHand_InvLess_than_60days           = 'Prev Month On Hand-Inv less than 60 days';      
 static Prev_YearOnHand_InvLess_than_60days           = 'Prev Yr On Hand-Inv less than 60 days';     
 static OnHand_InvLess_than_60days           = 'On Hand-Inv less than 60 days';     
 


  /**
   * Column names for Dealer details grid ended
   */

  // Constants for USD
  static USD: string = 'USD';
  // Constants for '$'
  static USD_SYMBOL: string = '$';
  // Constants for ','
  static COMMA: string = ',';
  // Constants for empty string ''
  static EMPTY_STRING: string = '';
  // Constants for select dealer popup heading
  static SELECT_DEALER_HEADER = 'Select Dealer';
  // Constants for default make code
  static DEFAULT_MAKECODE = 'TOYOTA';
  // Constants for ADD
  static ADD = 'ADD';
  static MAKE = 'MAKE';
  static FETCHED = 'FETCHED';
  // Constants for ADD
  static ADDED = 'ADDED';
  // Constants for UPDATE
  static UPDATE = 'UPDATE';
  // Constants for UPDATE
  static UPDATED = 'UPDATED';
  // Constants for UPDATE
  static LAST_UPDATE = 'LAST UPDATE';
  // Constants for UPDATE
  static DELETED = 'DELETED';
  static DELETED_DATE = 'DELETED DATE';
  // Constants for UPDATE
  static DECERTIFIED = 'DECERTIFIED';
  // Constants for UPDATE
  static UPDATE_FAIL = 'UPDATE_FAILED';
  // Constants for DELETE
  static DELETE_FAIL = 'DELETE_FAILED';
  // Constants for UPDATE
  static FAIL = 'FAIL';
  // No Vin found in vehicle details
  static NO_VIN_VEH_DETAILS = 'NO_VIN_VEH_DETAILS';
  // Column name for Certified
  static CERTIFIED_FAIL = 'CERTIFIED_FAIL';
  // Constants for YES
  static YES = 'YES';
  // Constants for NO
  static NO = 'NO';

  static ACKNOWLEDGE = 'Acknowledge';
  // Constant for dialog type
  static OK_CANCEL: string = 'OK-CANCEL';
  // Constants for ADD NEW VEHICLE
  static ADD_NEW_VEHICLE = 'ADD NEW VEHICLE';
  // Constants for  DE-CERTIFY VEHICLE
  static DE_CERTIFY_VEHICLE = 'DE-CERTIFY VEHICLE';

  static INVALID_CERTIFICATION = 'INVALID TIER CERTIFICATION';
  // constant for Sidenav already registered with that name
  static SIDENAV_NAME_REGISTERED_ALREADY: string = 'Sidenav already registered with that name';

  // constant for menu
  static MENU: string = 'menu';

  // constant for authorization
  static AUTHORIZATION_LOWER_CASE: string = 'authorization';
  // constant for Bearer
  static BEARER: string = 'Bearer ';

  // Column name for Accessories
  static CUSTOMER = 'CUSTOMER';
  static SALE_DATE = 'SALE DATE';
  static RDR_STATUS = 'RDR STATUS';
  static SALE_TYPE = 'SALE TYPE';
  static SALES_STATUS = 'SALES STATUS';
  static SALES_PERSON = 'SALES PERSON';
  static FI_MANAGER = 'F&I MANAGER';
  static STATUS = 'STATUS';
  static CERTIFICATION_STATUS = 'CERTIFICATION STATUS';
  // Toyota Application Title
  static TOYOTA_TITLE = 'TCUV';
  static WHOLESALE = 'WHOLESALE';
  static RETAIL_AMT = 'RETAIL AMT';
  static RETAIL_SUBMIT = 'RETAIL SUBMITTED';
  static STOCK = 'STOCK';
  static LOT_DAYS = 'LOT DAYS';
  static REASON = 'REASON';

  // Column name for Certification Number
  static DAYS_CERTIFIED = 'DAYS CERTIFIED';
  static DAYS_IN_60 = 'DAYS IN 60 DAY CYCLE';
  static STOCK_NUMBER = 'STOCK NUMBER';
  static UPDATED_DATE = 'UPDATED DATE';
  static CERTIFIED_BY = 'CERTIFIED BY';
  static LAST_UPDATED_BY = 'LAST UPDATED BY';
  static CERTIFICATION_TYPE = 'CERTIFICATION TYPE';
  static SALES_CODE = 'SALES CODE';
  static INSPECTION_RO = 'INSPECTION RO #';
  static INSPECTION_DATE = 'INSPECTION DATE';
  static REPAIR_ORDER1_FORM = 'REPAIR ORDER NUMBER1';
  static REPAIR_ORDER1_DATE = 'DATE OF FIRST REPAIR ORDER';
  static REPAIR_ORDER1_DEALER = 'REPAIR ORDER1 DEALER';
  static REPAIR_ORDER2_FORM = 'REPAIR ORDER NUMBER2';
  static REPAIR_ORDER2_DATE = 'DATE OF SECOND REPAIR ORDER';
  static REPAIR_ORDER2_DEALER = 'REPAIR ORDER2 DEALER';
  static AVG_TRANS_PRICE = 'AVERAGE TRANS PRICE';
  static RDRS = 'RDRS';
  static RDR = 'RDR';
  static REV = 'REV';
  static TOTAL = 'TOTAL';
  static MIXPERCENT = 'RDR % MIX';
  static AVG_RECON_COST = 'AVERAGE RECON COST';
  static AVG_TURN_RATE = 'AVERAGE TURN RATE';
  static CURRENT_INVENTORY = 'CURRENT INVENTORY';
  static INVENTORY = 'INVENTORY';
  static INVENTORYMIX = 'INVENTORY % MIX';
  static SERIES_CODE = 'SERIES CODE';
  static YEAR = 'YEAR';
  static CERTIFIED_SALES = 'CERTIFIED SALES';
  static CERTIFICATION = 'CERTIFICATION';
  static CERTIFIED_SALES_DAILY = 'CERTIFIED SALES DAILY';
  static NON_CERTIFIED_SALES = 'NON CERTIFIED SALES';
  static AVG_CERT_PRICE = 'AVERAGE CERT. PRICE';
  static AVG_NON_CERT_PRICE = 'AVERAGE NON-CERT. PRICE';
  static AVG_SALES = 'AVERAGE SALES';
  static TOTAL_COUNT = 'TOTAL COUNT';
  static DAILY = 'DAILY';
  static MTD = 'MTD';
  static OBJ = 'OBJ';
  static OBJ_PERCENT = 'OBJ %';
  static CERTIFIED_DAILY = 'CERTIFIED DAILY';
  static CERTIFICATION_DAILY = 'CERTIFICATION DAILY';
  static CERTIFIED_MONTHLY = 'CERTIFIED MONTHLY';
  static CERTIFICATION_MONTHLY = 'CERTIFICATION MONTHLY';
  static CERTIFICATION_YEARLY = 'CERTIFICATION YEARLY';
  static CERTIFICATION_CUSTOM = 'CERTIFICATION CUSTOM';
  static CERTIFICATION_TOTAL = 'CERTIFICATION TOTAL';
  static INVENTORY_TOTAL = 'INVENTORY TOTAL';
  static CERTIFIED_YEARLY = 'CERTIFIED YEARLY';
  static NON_CERTIFIED_DAILY = 'NON CERTIFIED DAILY';
  static TOTAL_LEXUS_DAILY = 'TOTAL LEXUS DAILY';
  static TOTAL_LEXUS_MONTHLY = 'TOTAL LEXUS MONTHLY';
  static TOTAL_LEXUS_YEARLY = 'TOTAL LEXUS YEARLY';
  static NON_CERTIFIED_MONTHLY = 'NON CERTIFIED MONTHLY';
  static NON_CERTIFIED_YEARLY = 'NON CERTIFIED YEARLY';
  static TOTAL_SALES = 'TOTAL SALES';
  static TOTAL_REVERSAL = 'TOTAL REVERSAL';
  static SALES = 'SALES';
  static AVAIL = 'AVAIL';
  static MONTH_OBJ = 'MONTH OBJ';
  static TOTAL_LEXUS_SALES_OBJ = 'TOTAL LEXUS SALES OBJ';
  static TOTAL_LEXUS_SALES_OBJ_PERCENT = 'TOTAL LEXUS SALES OBJ %';
  static CERTIFIED_SALES_OBJ = 'CERTIFIED SALES OBJ';
  static CERTIFIED_SALES_OBJ_PERCENT = 'CERTIFIED SALES OBJ %';
  static CERTIFICATION_OBJ = 'CERTIFICATION OBJ';
  static CERTIFICATION_OBJ_PERCENT = 'CERTIFICATION OBJ %';
  static PER_OBJ = 'MONTHLY % OF OBJ';
  static WHOLESALE_DAILY = 'WHOLESALE DAILY';
  static WHOLESALE_MONTHLY = 'WHOLESALE MONTHLY';
  static WHOLESALE_YEARLY = 'WHOLESALE YEARLY';
  static ON_HAND = 'ON HAND';
  static CERT = 'CERT.';
  static PER_CERT_AVAIL = '%CERT/AVAIL';
  static PER_RTL_AVAIL = '%RTL/AVAIL';
  static CERTIFIED_INVENTORY_MONTHLY = 'CERTIFIED INVENTORY MONTHLY';
  static CERTIFIED_INVENTORY_YEARLY = 'CERTIFIED INVENTORY YEARLY';
  static CERTIFIED_SALES_MONTHLY = 'CERTIFIED SALES MONTHLY';
  static CERTIFIED_SALES_YEARLY = 'CERTIFIED SALES YEARLY';
  static CERTIFIED_SALES_CUSTOM = 'CERTIFIED SALES CUSTOM';
  static LAST_MONTH_CERTIFICATION = 'LAST MONTH CERTIFICATION';
  static LAST_MONTH_RDR = 'LAST MONTH SALES';
  static CURRENT_MONTH_CERTIFICATION = 'CURRENT MONTH CERTIFICATION';
  static CURRENT_MONTH_RDR = 'CURRENT MONTH SALES';
  static INVENTORY_LESSTHAN_60 = '<= 60 DAY';
  static INV_LESSTHAN_60 = '< 60 DAY INVENTORY';
  static SALES_DIFF = 'SALES ( + / - )';
  static SALES_PERCENT = 'SALES %';
  static CERTIFICATION_DIFF = 'CERTIFICATON ( + / - )';
  static CERTIFICATION_PERCENT = 'CERTIFICATON %';
  static NON_CERTIFIED = 'NON CERTIFIED';
  static CERTSALESRAITO = 'CERT : SALES RATIO';
  static CONSULTANT_NAME = 'CONSULTANT NAME';
  static UID = 'UID';
  static YTD_RDR = 'YTD RDR';
  static YTD_REV = 'YTD REV';
  static YTD_SALES = 'YTD SALES';
  static MTD_RDR = 'MTD RDR';
  static MTD_REV = 'MTD REV';
  static MTD_SALES = 'MTD SALES';
  static SALES_AVAIL = 'SALES AVAIL';
  static REGION_OR_DIST = 'DISTRICT/REGION';
  static REGION_OR_CONSULTANT = 'REGION/CONSULTANT';
  static TYPE = 'TYPE';

  // Lexus Application Title
  static LEXUS_TITLE = 'LCUV';

  static REFRESH_TOKEN_TIME_PERIOD: number = 55 * 60 * 1000; // => 55 min
  // Constant for Time to verify if token is refreshed => min * seconds * milliseconds
  static REFRESH_TOKEN_VERIFICATION_TIME_PERIOD: number = 60 * 60 * 1000; // => 60 min

  static UCM_APP_TOYOTA = 'UCM-App-Toyota';

  static UCM_APP_LEXUS = 'UCM-App-Lexus';

  static CVA_APP_TOYOTA = 'CVA-App-Admin-Toyota';

  static CVA_APP_LEXUS = 'CVA-App-Admin-Lexus';

  static CVA_APP_SUPER_TOYOTA = 'CVA-App-SuperAdmin-Toyota';

  static CVA_APP_SUPER_LEXUS = 'CVA-App-SuperAdmin-Lexus';

  static CVA_APP_READONLY_TOYOTA = 'CVA-App-ReadOnly-Toyota';

  static CVA_APP_READONLY_LEXUS = 'CVA-App-ReadOnly-Lexus';

  static CVA_APP_SUPERADMIN_TMEX = 'CVA-App-SuperAdmin-TMEX';

  static CVA_APP_ADMIN_TMEX = 'CVA-App-Admin-TMEX';

  static CVA_APP_READONLY_TMEX = 'CVA-App-ReadOnly-TMEX';

  static CVA_APP_SUPERADMIN_GST = 'CVA-App-SuperAdmin-GST';

  static CVA_APP_SUPERADMIN_SET = 'CVA-App-SuperAdmin-SET';
}


