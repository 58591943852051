import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';
import * as fromRoot from '../../../reducers';
import { ExportToExcelService } from '../../../services/common/excel-service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import * as models from '../../../services/model/models';
import { DealerDetailView } from '../../../services/view-model/dealer-detail-view';
import { UCInventorySummaryView } from '../../../services/view-model/inventory-summary-view';
import * as viewModels from '../../../services/view-model/view-models';
import { ScreenMessages } from '../../../shared/master-data/messages';
import { ViewConstants } from '../../../shared/shared.constants';
import { InventorySummaryGridColumns } from './json/UCInventorySummaryGridColumns';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as alasql from 'alasql';



@Component({
  selector: 'uc-inventory',
  templateUrl: 'same-day-cert-report-page.html'
})
export class UCSameDayCertPageReportComponent implements OnInit, OnDestroy {
  // Observable to save inventory screen data
  inventorySummaryData$: Observable<UCInventorySummaryView>;
  // Subscriber for Inventory Data
  inventorySummarySubscriber: any;
  routeParamSubscriber: any;
  // Array variable used to store columns
  public columnDefs: any[] = [];
  // Array variable to keep the original data for filter purpose
  originalData: Array<models.UCInventorySummary>;
  rowsData: Array<models.UCInventorySummary>;
  // Array variable to keep the filtered data for filter purpose
  filteredData: Array<models.UCInventorySummary>;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];
  // Boolean to reveal whether the dealer code is selected or not
  isInventorySelected: boolean = false;
  // certificationStatus
  selectedCertificationStatus: string = '';
  // makeCode
  selectedMakeCode: string = '';
  // To store seleted vehicle index
  public selectedVehicleIndex: number = 0;
  // Boolean variable to show/hide the clear all button
  showClearAllButton: boolean;
  // Variable to display the inventories count
  totalInventoriesCount: Number = 0;
  // Observable to obtain VehicleDetailView object
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;
  // Observable to obtain RdrDetailView object
  rdrDetails$: Observable<viewModels.RdrDetailView>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;
  // Dealer code entered in search toolbar
  enteredDealerCode: string;
  // vin entered in search toolbar
  enteredvin: string;
  // To hold the last sort event
  currentSortEvent: any;
  // to clear the current sorting for all columns
  clearSort: boolean = false;
  dealerCodes: Array<any> = [];
  reportType: string;

  fromDate: string;
  labelText: string = 'Created Date';
  toDate: string;
  regionSelection: string;
  districtSelection: string;
  reportStartDate: string;

  reportEndDate: string;

  reportHeaderText: string = '';

  reportFileName: string = '';
  regionItems: Array<any>;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  selectedMonth: string;
  selectedYear: string;
  // Private array variable to display the rows in grid
  private _rows: Array<models.UCInventorySummary>;
  // Property to set and get the rows
  get rows(): Array<models.UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<models.UCInventorySummary>) {
    if (value.length === 1 || value.length === 0) {
      this.totalInventoriesCount = 0;
    } else {
      this.totalInventoriesCount = value.length - 1;
    }
    this._rows = value;
  }
  // selected Disclaimer
  private row: models.UCInventorySummary = {};
  /**
   * Constructor for UCInventoryPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    public sharedFuncService: SharedFunctionService,
    public sharedDataService: SharedDataService,
    public router: Router,
    public activeRouter: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private cb: CurrencyPipe,
    private exportToExcelService: ExportToExcelService) {
    // this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    this.inventorySummaryData$ = store.select(fromRoot.getVehicleReport);
    if (isNil(this.sharedDataService.dealerData)) {
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    }
    let date = new Date();
    this.selectedMonth = (date.getMonth() + 1).toString();
    this.selectedYear = (date.getFullYear()).toString();
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.onLoad('m');

    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
    this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
    
    this.inventorySummarySubscriber = this.inventorySummaryData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        this.enteredDealerCode = '';
        this.enteredDealerCode = (<any>Object).assign(this.enteredDealerCode, '');
        this.enteredvin = '';
        this.enteredvin = (<any>Object).assign(this.enteredvin, '');
        this.isInventorySelected = false;
        this.selectedCertificationStatus = '';
        this.selectedMakeCode = '';
        if (!isNil(data.message)) {
          if (data.message.message === ScreenMessages.NO_DATA) {
            // this.populateRegionData();
          } else {
            this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
              data.message
            ));
          }
        } else {
          if (data.inventories.length > 1) {
            if (this.regionSelection === undefined) {
              this.rows = cloneDeep([]);
              this.rowsData = cloneDeep([]);
            } else {
              let copyData = data.inventories;
              let testFilter = copyData;
              // let testFilter = this.totalCount(copyData);
              let dupData = new Array<any>();
              this.reportStartDate = data.startDate;
              this.reportEndDate = data.endDate;
              // let lastRow = copyData.splice(-1, 1);
              let lastRow = testFilter.splice(-1, 1);
              let indexNum = 0;
              let distNo;
              let regName;
              let distFilter = 0;
              let regFilter = 0;
              if (!isNil(this.sharedDataService.dealerData)) {
                /************************************************/

                testFilter = testFilter.filter(element => {
                  if (this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd).length > 0) {
                    element.areaRegionNo = this.sharedDataService.dealerData
                      .filter(t => t.dealerCode === element.dealerCd)[0].areaRegionNo;
                    element.regionName = this.sharedDataService.dealerData
                      .filter(t => t.dealerCode
                        === element.dealerCd)[0].areaRegionName;
                    element.districtNo = this.sharedDataService.dealerData
                      .filter(t => t.dealerCode
                        === element.dealerCd)[0].districtNo;
                    distNo = this.sharedDataService.dealerData
                      .filter(t => t.dealerCode
                        === element.dealerCd)[0].districtNo;
                    regName = this.sharedDataService.dealerData
                      .filter(t => t.dealerCode
                        === element.dealerCd)[0].areaRegionName;
                  } else element.districtNo = '';

                  if ((this.regionSelection === 'ALL' || this.regionSelection === undefined))
                    regFilter = 0;
                  else regFilter = 1;
                  if (this.districtSelection === 'ALL' || this.districtSelection === undefined)
                    distFilter = 0;
                  else distFilter = 1;

                  if (regFilter === 0 && distFilter === 0) {
                    indexNum++;
                    return element;
                  } else if (regFilter === 1 && distFilter === 0) {
                    if (regName === this.regionSelection) {
                      indexNum++;
                      return element;
                    }
                  } else if (regFilter === 0 && distFilter === 1) {
                    if (distNo === this.districtSelection) {
                      indexNum++;
                      return element;
                    }
                  } else {
                    if (distNo === this.districtSelection && regName === this.regionSelection) {
                      indexNum++;
                      return element;
                    }
                  }
                });

                /************************************************/
                let date = new Date();
                testFilter.forEach(element => {
                  if (!isNil(element.dealerCd)) {
                    let dealer = this.sharedDataService.dealerData
                      .filter(t => t.dealerCode === element.dealerCd);
                    if (dealer.length > 0) {
                      let salesTrendItem: any = {
                        'dealerCd': element.dealerCd,
                        'districtNumber': element.districtNumber,
                        'dealerName': element.dealerName,
                        'regionName': element.regionName,
                        'regionNumber': element.regionNumber,
                        'certAfterSaleCount': element.certAfterSaleCount,
                        'certAfterSalePercent': element.certAfterSalePercent,
                        'sameDayCertCount': element.sameDayCertCount,
                        'sameDayCertPercent': element.sameDayCertPercent,
                        'totSalesCount': element.totSalesCount,
                        'totalPercent': element.totalPercent,
                      };
                      if (dealer[0].terminatedFlag === 'N' && dealer[0].areaRegionNo !== '83')
                        dupData.push(salesTrendItem);
                      else if (new Date(dealer[0].terminatedDate).getFullYear()
                        === date.getFullYear() && dealer[0].areaRegionNo !== '83')
                        dupData.push(salesTrendItem);
                    }
                  }
                });
                /****************************************** */
              }

              dupData.push(lastRow[0]);
              this.rows = cloneDeep(sortBy(dupData, 'VIN'));
              this.rowsData = cloneDeep(sortBy(dupData, 'VIN'));
              this.originalData = cloneDeep(data.inventories);
            }
          } else {
            this.rows = cloneDeep([]);
            this.rowsData = cloneDeep([]);
          }

        }
      }
    });
  }
  onLoad(event: any) {
    let defData = {
      month: this.selectedMonth,
      year: this.selectedYear
    };
    if (!isNil(this.sharedDataService.dealerData)) {
      this.sharedDataService.dealerData.map(e => {
        this.dealerCodes.push(e.dealerCode);
      });
    }
    this.sharedFuncService.setCurrentScreenName(
      ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    this.columnDefs = InventorySummaryGridColumns.mtdGridColumns;
    this.sharedDataService.setSelectedVehicle('');
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    let report = {
      reportMode: '36',
      fromDate: null,
      toDate: null,
      reportType: this.reportType,
      makeCode: this.sharedDataService.brandName,
      from: 0,
      // regionCode: this.region,
      // districtNo: this.district,
      dealerCodes: this.dealerCodes
    };
    // this.store.dispatch(
    //   new vehicleReportActions.GetInventoryReport(report));

  }

  totalCount(returnArray: any) {
    let date = new Date();
    let copyReturnArray;
    copyReturnArray = returnArray;
    let item2: any = {
      'dealerCd': ''
    };
    for (let j = 0; j < copyReturnArray.length; j++) {
      item2['dealerCd'] = 'TOTAL';

      const _dealer = this.sharedDataService.dealerData
        .filter(t => t.dealerCode === copyReturnArray[j].dealerCd);
      if ((_dealer[0].terminatedFlag !== 'N' && new Date(_dealer[0].terminatedDate).getFullYear()
        !== date.getFullYear()) || _dealer[0].areaRegionNo === '83')
        continue;

      if (item2.hasOwnProperty('invCount')) {
        item2['invCount'] +=
          copyReturnArray[j].invCount;
      } else {
        item2['invCount'] =
          copyReturnArray[j].invCount;
      }
      if (item2.hasOwnProperty('silverInvCount')) {
        item2['silverInvCount'] +=
          copyReturnArray[j].silverInvCount;
      } else {
        item2['silverInvCount'] =
          copyReturnArray[j].silverInvCount;
      }

      if (item2.hasOwnProperty('goldInvCount')) {
        item2['goldInvCount'] +=
          copyReturnArray[j].goldInvCount;
      } else {
        item2['goldInvCount'] =
          copyReturnArray[j].goldInvCount;
      }

      if (item2.hasOwnProperty('certifiedInventory')) {
        item2['certifiedInventory'] +=
          copyReturnArray[j].certifiedInventory;
      } else {
        item2['certifiedInventory'] =
          copyReturnArray[j].certifiedInventory;
      }

      if (item2.hasOwnProperty('silverCertifiedInventory')) {
        item2['silverCertifiedInventory'] +=
          copyReturnArray[j].silverCertifiedInventory;
      } else {
        item2['silverCertifiedInventory'] =
          copyReturnArray[j].silverCertifiedInventory;
      }

      if (item2.hasOwnProperty('goldCertifiedInventory')) {
        item2['goldCertifiedInventory'] +=
          copyReturnArray[j].goldCertifiedInventory;
      } else {
        item2['goldCertifiedInventory'] =
          copyReturnArray[j].goldCertifiedInventory;
      }

      if (item2.hasOwnProperty('dailyCertifiedInventory')) {
        item2['dailyCertifiedInventory'] +=
          copyReturnArray[j].dailyCertifiedInventory;
      } else {
        item2['dailyCertifiedInventory'] =
          copyReturnArray[j].dailyCertifiedInventory;
      }

      if (item2.hasOwnProperty('silverDailyCertifiedInventory')) {
        item2['silverDailyCertifiedInventory'] +=
          copyReturnArray[j].silverDailyCertifiedInventory;
      } else {
        item2['silverDailyCertifiedInventory'] =
          copyReturnArray[j].silverDailyCertifiedInventory;
      }

      if (item2.hasOwnProperty('goldDailyCertifiedInventory')) {
        item2['goldDailyCertifiedInventory'] +=
          copyReturnArray[j].goldDailyCertifiedInventory;
      } else {
        item2['goldDailyCertifiedInventory'] =
          copyReturnArray[j].goldDailyCertifiedInventory;
      }

      if (item2.hasOwnProperty('dailyCertifiedSalesCount')) {
        item2['dailyCertifiedSalesCount'] +=
          copyReturnArray[j].dailyCertifiedSalesCount;
      } else {
        item2['dailyCertifiedSalesCount'] =
          copyReturnArray[j].dailyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('silverDailyCertifiedSalesCount')) {
        item2['silverDailyCertifiedSalesCount'] +=
          copyReturnArray[j].silverDailyCertifiedSalesCount;
      } else {
        item2['silverDailyCertifiedSalesCount'] =
          copyReturnArray[j].silverDailyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('goldDailyCertifiedSalesCount')) {
        item2['goldDailyCertifiedSalesCount'] +=
          copyReturnArray[j].goldDailyCertifiedSalesCount;
      } else {
        item2['goldDailyCertifiedSalesCount'] =
          copyReturnArray[j].goldDailyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('certifiedSalesCount')) {
        item2['certifiedSalesCount'] +=
          copyReturnArray[j].certifiedSalesCount;
      } else {
        item2['certifiedSalesCount'] =
          copyReturnArray[j].certifiedSalesCount;
      }

      if (item2.hasOwnProperty('silverCertifiedSalesCount')) {
        item2['silverCertifiedSalesCount'] +=
          copyReturnArray[j].silverCertifiedSalesCount;
      } else {
        item2['silverCertifiedSalesCount'] =
          copyReturnArray[j].silverCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('goldCertifiedSalesCount')) {
        item2['goldCertifiedSalesCount'] +=
          copyReturnArray[j].goldCertifiedSalesCount;
      } else {
        item2['goldCertifiedSalesCount'] =
          copyReturnArray[j].goldCertifiedSalesCount;
      }
    }
    returnArray.push(item2);
    return returnArray;
  }

  populateRegionData() {
    let regionArrayCopy = new Array<any>();
    let regionUniqueArrayCopy = new Array<any>();
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          let data = {
            name: element.areaRegionName,
            code: element.areaRegionNo,
            makeCode: element.brand
          };
          regionArrayCopy.push(data);
        }
      });
    }
    this.regionItems =
      regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);

    for (let i = 0; i < this.regionItems.length; i++) {
      let data = {
        name: this.regionItems[i],
        code: regionArrayCopy
          .filter(t => t.name === this.regionItems[i])[0].code,
      };
      regionUniqueArrayCopy.push(data);
    }

    return sortBy(regionUniqueArrayCopy, 'name');
  }

  reportLoad(event: any) {
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());


    this.regionSelection = event.region;
    this.districtSelection = event.district;
    this.dealerCodes = [];
    let distFilter = 0;
    let regFilter = 0;
    this.regionSelection = event.region;
    this.districtSelection = event.district;
    this.reportType = event.reportType;
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.selectedMonth = event.month;
    this.selectedYear = event.year;
    if ((this.regionSelection === 'ALL' || this.regionSelection === undefined))
      regFilter = 0;
    else regFilter = 1;
    if (this.districtSelection === 'ALL' || this.districtSelection === undefined)
      distFilter = 0;
    else distFilter = 1;

    if (!isNil(this.sharedDataService.dealerData)) {
      this.sharedDataService.dealerData.map(e => {
        if (regFilter === 0 && distFilter === 0) {
          this.dealerCodes.push(e.dealerCode);
        } else if (regFilter === 1 && distFilter === 0) {
          if (e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        } else if ((regFilter === 0 && distFilter === 1)) {
          if (e.districtNo === this.districtSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        } else {
          if (e.districtNo === this.districtSelection
            && e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        }
      });
    }
    let date = new Date();
    this.sharedFuncService.setCurrentScreenName(
      ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    if (this.reportType === 'm') {
      this.columnDefs = InventorySummaryGridColumns.mtdGridColumns;
    } else if (this.reportType === 'y') {
      this.columnDefs = InventorySummaryGridColumns.ytdGridColumns;
    } else if (this.reportType === 'c') {
      this.columnDefs = InventorySummaryGridColumns.customGridColumns;
    }

    this.sharedDataService.setSelectedVehicle('');

    let report = {
      reportMode: '36',
      currentMonth: this.selectedMonth,
      currentYear: this.selectedYear,
      currentDay: date.getDate(),
      makeCode: this.sharedDataService.brandName,
      regionCode: this.regionSelection,
      districtNo: this.districtSelection,
      dealerCodes: this.dealerCodes,
      reportType: this.reportType,
      cStartDate: moment(this.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      cEndDate: moment(this.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  getMonthlyObj(element: any) {
    let date = new Date();
    let currentMonth = this.sharedFuncService.getMonth();
    if (currentMonth === 1) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].janObjective;
    } else if (currentMonth === 2) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].febObjective;
    } else if (currentMonth === 3) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].marObjective;
    } else if (currentMonth === 4) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].aprObjective;
    } else if (currentMonth === 5) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].mayObjective;
    } else if (currentMonth === 6) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].junObjective;
    } else if (currentMonth === 7) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].julObjective;
    } else if (currentMonth === 8) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].augObjective;
    } else if (currentMonth === 9) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].sepObjective;
    } else if (currentMonth === 10) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].octObjective;
    } else if (currentMonth === 11) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].novObjective;
    } else if (currentMonth === 12) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].decObjective;
    }
  }


  refreshFilters() {
    this.showClearAllButton = false;
    if (!isNil(this.currentSortEvent)) {
      this.applyInventorySort(this.currentSortEvent);
    }
    this.clearSort = false;
    let gridColumn: any;
    if (this.reportType === 'm')
      InventorySummaryGridColumns.mtdGridColumns.forEach(column => {
        column.filterValue = '';
      });
    else
      InventorySummaryGridColumns.ytdGridColumns.forEach(column => {
        column.filterValue = '';
      });
  }

  applyInventorySort(event: any) {
    this.currentSortEvent = event;
    let rowCopy = this.rows.slice();;
    let sortedRow = cloneDeep(this.applySort(rowCopy, event));
    this.rows = sortedRow;
    // this.rows =
    // cloneDeep(this.sharedFuncService.applySort(this.rows, event));
  }

  /**
   * To apply Sorting
   */
  applySort(rows: any[], event: any): any[] {
    let sortedRows = [];
    if (event.columnName === 'sameDayCertPercent' || event.columnName === 'certAfterSalePercent' || event.columnName === 'totalPercent') {
      sortedRows =  sortBy(rows, item => parseFloat(item[event.columnName]));
    } else {
      sortedRows = sortBy(rows, event.columnName);
    }
    return event.sortOrder === 'ASC' ? sortedRows : sortedRows.reverse();
  }

  ApplyFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.filterType === filter.filterType
          && data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      // this.filteredData = cloneDeep(this.originalData);
      this.filteredData = cloneDeep(this.rowsData);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          if (arrayElement.filterType === models.FilterType.CONTAINS) {
            this.applyContainsFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
            this.applyStartsWithFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.SPACES) {
            this.applySpacesFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
            this.applyPriceFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
            this.applyDateFilter(arrayElement);
          }
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllButton = true;
      } else {
        this.showClearAllButton = false;
      }
      // if (this.filteredData.length === 0) {
      //     let test: models.UCInventorySummary = {};
      //     test.vin = '';
      //     this.filteredData.push(test);
      // }
      this.rows = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
      return (!isNil(propertyValue) &&
        propertyValue.toLowerCase()
          .indexOf(filterValue) > -1);
    });
  }

  applyStartsWithFilter(filterElement: models.Filter) {
    let indexNum = -1;
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      indexNum++;
      let propertyValue = this.accessProperties(inventory, filterElement.columnname);
      return (!isNil(propertyValue) &&
        String(propertyValue).toLowerCase()
          .startsWith(filterValue));
    });
  }

  applySpacesFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      return !isNil(inventory.accessoriesDetails) &&
        inventory.accessoriesDetails !== undefined
        && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
    });
  }

  accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
    let filterValues = String(value).split(' ');
    let returnValue = false;
    let positiveCount = 0;
    if (!isNil(accessories) && accessories.length > 0) {
      filterValues.forEach(filter => {
        if (accessories.filter(acc =>
          acc.accessssoriesCode.trim().toLowerCase() === filter ||
          acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
          positiveCount++;
        }
      });
    }
    if (positiveCount === filterValues.length) {
      return true;
    }
    return false;
  }

  applyPriceFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyDateFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    if (fullFilterValue.length > 1) {
      let firstLetter = fullFilterValue.substr(0, 1);
      let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
      this.filteredData = this.filteredData.filter(inventory => {
        let propValue = this.accessProperties(inventory, filterElement.columnname);
        if (!isNil(propValue)) {
          if (firstLetter === '<') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
            }
          } else if (firstLetter === '>') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
            }

          } else if (firstLetter === '=') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }

          } else {
            filterVal = fullFilterValue.
              substr(0, fullFilterValue.length);
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }
          }
        }
        return true;
      });
    }
  }

  accessProperties(object: any, str: string) {
    let propertiesString = str.split('.');
    let i = 0;
    propertiesString.forEach(val => {
      object = object[propertiesString[i]];
      i++;
    });
    return object;
  }

  clearAllFilters() {
    // this.rows = cloneDeep(this.originalData);
    // this.filteredData = cloneDeep(this.originalData);
    this.rows = cloneDeep(this.rowsData);
    this.filteredData = cloneDeep(this.rowsData);
    this.filterArray = [];
    this.refreshFilters();
  }

  getFormattedTime() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + '' + m + '' + d + '' + h + '' + mi + '' + s;
  }

  getReportInitData() {
    this.reportHeaderText
      = 'Same Day Cert Report';
    this.reportFileName =
      'Same_Day_Cert_SILVER' + this.getFormattedTime();
  }

  getFormattedDateForDisplay() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    return m + '/' + d + '/' + y;
  }

  getDateRange(type: string): string {
    let date = new Date();
    if (type === 'm' || type === 'y') {
      return this.sharedFuncService.convertDateDisplay(this.reportStartDate)
        + ' THRU ' + this.sharedFuncService.convertDateDisplay(this.reportEndDate);
    } else if (type === 'd') {
      return this.convertDateDisplay(date) + ' THRU ' + this.convertDateDisplay(date);
    }
  }

  convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return month + '/' + dt + '/' + year;
  }


  // To print the Inventory grid data
  downLoadPdf() {
    this.getReportInitData();
    this.reportHeaderText = this.reportHeaderText.toUpperCase();
    let inventoryData = this.inventoryGridData();
    let doc = new jsPDF('landscape', 'mm', 'a2');
    let totalPagesExp = '{total_pages_count_string}';
    // doc.setFontSize(15);
    // doc.text('Report Generated Date: ' + this.getFormattedDateForDisplay(), 500, 22);
    doc.setFontSize(20);
    doc.text(170, 15, ViewConstants.APPLICATION_TITLE_FOR_REPORT);
    doc.setFontSize(15);
    doc.text(180, 25, this.reportHeaderText);
    doc.text(230, 32, this.getDateRange('m'));


    let pageContent = function (data) {
      let str = 'Page ' + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' of ' + totalPagesExp;
      }
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.setFontSize(12);
      doc.text(str, 5, pageHeight - 10);
    };

    doc.autoTable(inventoryData.columns, inventoryData.rows, {
      startY: 40,
      styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
      columnStyles: { text: { columnWidth: 'auto' } },
      addPageContent: pageContent
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(this.reportFileName + '.pdf');
    this.sharedDataService.showLoader = false;
  }

  // To download the xlsx in inventory screen
  downLoadXlsx() {
    this.getReportInitData();
    let exportData = {
      'title': this.reportHeaderText.toUpperCase(),
      'range': this.getDateRange(this.reportType),
      'header': this.inventoryGridData().columns,
      'data': this.inventoryGridData().rows,
      'isTotalGrid': false,
      'totalHeader': null,
      'totalData': null,
      'generationDate': this.getFormattedDateForDisplay(),
      'fileName': 'Same_Day_Cert_report'
    };
    this.exportToExcelService.exportAsExcelFile(exportData);
    this.sharedDataService.showLoader = false;
  }

  // To download the csv in inventory screen
  downLoadCsv() {
    let tempData: any;
    let inventoryDataNew: Array<any> = [];
    this.rows.forEach(row => {
      tempData = {};
      if (this.reportType === 'm')
        InventorySummaryGridColumns.mtdGridColumns.forEach(column => {
          let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempData[column.key] = columnField;
        });
      else
        InventorySummaryGridColumns.ytdGridColumns.forEach(column => {
          let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempData[column.key] = columnField;
        });

      inventoryDataNew.push(tempData);
    });
    alasql('SELECT * INTO CSV("inventorySummary.csv",{headers:true, separator:","}) FROM ?',
      [inventoryDataNew]);
    this.sharedDataService.showLoader = false;

  }

  // To get inventory grid data
  inventoryGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    let gridColumn = this.reportType === 'm' ?
      InventorySummaryGridColumns.mtdGridColumns : this.reportType === 'c' ?
        InventorySummaryGridColumns.customGridColumns : InventorySummaryGridColumns.ytdGridColumns;
    if (gridColumn && this.rows) {
      gridColumn.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.rows.forEach(row => {
        tempRows = [];
        gridColumn.forEach(column => {
          let columnField = this.sharedFuncService.recurseObjProp(
            row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }


  ngOnDestroy() {
    if (this.inventorySummarySubscriber) {
      this.inventorySummarySubscriber.unsubscribe();
    }
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    // this.inventorySummaryData$ = null;
  }

}
