/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';
import { Headers, RequestOptionsArgs, Response, URLSearchParams } from '@angular/http';


import { Observable } from 'rxjs';
import * as models from '../model/models';
// TODO: correctly add the JSON so that it can be read
import { HttpClient } from '../../shared/components/helpers/http-client';
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedDataService } from '../common/shared-data.service';
// import { USE_MOCK_SERVICE } from '../constants';
import { cloneDeep, isNil } from 'lodash';
import { SharedFunctionService } from '../common/shared-function.service';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators'

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class RdrDetailsService {
  public defaultHeaders: Headers = new Headers();
  // To populate the Base Path
  protected basePath = environment.BASE_SERVICE_URL;
  protected getVehicleServicePath = environment.GET_VEHICLE_SERVICE_URL;
  protected saveRdrServicePath = environment.SAVE_RDR_SERVICE_URL;
  protected updateRdrServicePath = environment.UPDATE_RDR_SERVICE_URL;
  protected deleteRdrServicePath = environment.DELETE_RDR_SERVICE_URL;
  protected reverseRdrServicePath = environment.REVERSE_RDR_SERVICE_URL;
  protected staffMasterServicePath = environment.SM_API_URL;
  /***
   * Constructor of Inventory Detail Service to inject the angluar http
   */
  constructor(private logger: Logger,
    public sharedDataService: SharedDataService,
    protected httpClient: HttpClient,
    private sharedFuncService: SharedFunctionService) { }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public getRdrByVin(rdr: models.Rdr): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.getVehicleServicePath + '?vin=' + rdr.vin;
    if (!isNil(rdr.dealerCd)) {
      path = path + '&dealerCd=' + rdr.dealerCd;
    }
    path = path + '&mode=rdr';
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      //   UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicle.vin);
      // let vehicle = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //   observer.next(vehicle);
      //   observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
        .pipe(map((response: any) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public getRdrByVinblur(vin: string): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.getVehicleServicePath + '?vin=' + vin;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      //   UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicle.vin);
      // let vehicle = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //   observer.next(vehicle);
      //   observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
        .pipe(map((res: any) => {
          let response = res?.body;
          const isTmnaDealer = !['83', '60', '50'].includes(this.sharedDataService?.selectedDealer?.areaRegionNo);
          if (res.status === 200 &&
            ((!isNil(response.makeCode)
              && response.makeCode === 'TOYOTA'
              && !isNil(response.certificationStatus)
              && response.certificationStatus === 'C')
              || (!isNil(response.makeCode) &&
                (response.makeCode === 'LEXUS' ||
                  response.makeCode === 'LEX')))) {
            return response;
          } 
          // else if (isTmnaDealer && response?.makeCode === 'TOYOTA'
          //   && response?.certificationStatus?.toLowerCase() === 'u') {
          //   return response;
          // }
          else if ((!isNil(response.makeCode) &&
            response.makeCode === 'TOYOTA' &&
            !isNil(response.certificationStatus)
            && response.certificationStatus !== 'C')) {
            let tempVehicle = cloneDeep(response);
            tempVehicle.code = 'uncertified';
            return tempVehicle;
          } else {
            throw new Error();
          }
        }))
    }
  }

  /***
 * This method is used to save the Vehicle Inventory Detail data
 * for the given VIN number
 */
  public saveRdr(rdr: models.Rdr): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.saveRdrServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'POST',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(rdr);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.post(path, rdr, requestOptions)
        .pipe(map((res: any) => {
          let response = res.body;
          if (res.status === 201 || res.status === 200) {
            if (response.certificationNumber !== undefined) {
              let tempVehicle = cloneDeep(rdr);
              tempVehicle.certificationNumber = response.certificationNumber;
              tempVehicle.model = response.model;
              if (!isNil(response.certification)) {
                tempVehicle.certificationStatus = response.certification.certificationStatus;
                tempVehicle.deCertifyReason = response.certification.deCertifyReason;
                if (isNil(tempVehicle.certification)) {
                  tempVehicle.certification = <models.CertificationDetails>new Object();
                  tempVehicle.certification.certificationDate =
                    response.certification.certificationDate;
                  tempVehicle.certification.forceCertificationFlag =
                    response.certification.forceCertificationFlag;
                } else {
                  tempVehicle.certification.certificationDate =
                    response.certification.certificationDate;
                  tempVehicle.certification.forceCertificationFlag =
                    response.certification.forceCertificationFlag;
                }
              }
              return tempVehicle;
            } else {
              let tempVehicle = cloneDeep(rdr);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              if (!isNil(response.Code)) {
                tempVehicle.code = response.Code;
                tempVehicle.desc = response.desc;
                tempVehicle.deCertifyReason = response.deCertifyReason;
              }
              return tempVehicle;
            }


          } else
            return false;
        }));
    }
  }

  /***
   * This method is used to update the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public updateRdr(rdr: models.RdrDetail): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    // if (!isNil(vehicle.certificationNumber) && !isEmpty(String(vehicle.certificationNumber))) {
    //     path = path + '&certificateNumber=' + vehicle.certificationNumber;
    // }
    // else {
    //     path = path + '&certificateNumber=0';
    // }
    let path = this.updateRdrServicePath;

    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(rdr);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.put(path, rdr, requestOptions)
        .pipe(map((res: any) => {
          let response = res;
          if (res.status === 200 || res.status === 201) {
            if (!isNil(response.certificationNumber)) {
              let tempVehicle = cloneDeep(rdr);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              tempVehicle.certificationNumber = response.certificationNumber;
              if (!isNil(response.certification)) {
                tempVehicle.certificationStatus = response.certification.certificationStatus;
                tempVehicle.deCertifyReason = response.certification.deCertifyReason;
                if (isNil(tempVehicle.certification)) {
                  tempVehicle.certification = <models.CertificationDetails>new Object();
                  tempVehicle.certification.certificationDate =
                    response.certification.certificationDate;
                  tempVehicle.certification.forceCertificationFlag =
                    response.certification.forceCertificationFlag;
                } else {
                  tempVehicle.certification.certificationDate =
                    response.certification.certificationDate;
                  tempVehicle.certification.forceCertificationFlag =
                    response.certification.forceCertificationFlag;
                }
              }
              return tempVehicle;
            } else {
              let tempVehicle = cloneDeep(rdr);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              if (!isNil(response.Code)) {
                tempVehicle.code = response.Code;
                tempVehicle.desc = response.desc;
                tempVehicle.deCertifyReason = response.deCertifyReason;
              }
              return tempVehicle;
            }
          } else {
            return false;
          }
        }));
    }
  }


  /***
* This method is used to save the Vehicle Inventory Detail data
* for the given VIN number
*/
  public deleteRdr(vehicle: models.Vehicle): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.deleteRdrServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.put(path, vehicle, requestOptions)
        .pipe(map((response: any) => {
          if (response.status === 201 || response.status === 200) {
            return response.body;
          } else
            return false;
        }));
    }
  }

  /***
 * This method is used to update the Vehicle Inventory Detail data
 * for the given VIN number
 */
  public reverseRdr(rdr: models.RdrDetail): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    // if (!isNil(vehicle.certificationNumber) && !isEmpty(String(vehicle.certificationNumber))) {
    //     path = path + '&certificateNumber=' + vehicle.certificationNumber;
    // }
    // else {
    //     path = path + '&certificateNumber=0';
    // }
    let path = this.reverseRdrServicePath;

    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(rdr);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.put(path, rdr, requestOptions)
        .pipe(map((res: any) => {
          let response = res.body;
          if (res.status === 200 || res.status === 201) {
            if (!isNil(response.certificationNumber)) {
              let tempVehicle = cloneDeep(rdr);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              tempVehicle.certificationNumber = response.certificationNumber;
              if (!isNil(response.certification)) {
                tempVehicle.certificationStatus = response.certification.certificationStatus;
                tempVehicle.deCertifyReason = response.certification.deCertifyReason;
                if (isNil(tempVehicle.certification)) {
                  tempVehicle.certification = <models.CertificationDetails>new Object();
                  tempVehicle.certification.certificationDate =
                    response.certification.certificationDate;
                  tempVehicle.certification.forceCertificationFlag =
                    response.certification.forceCertificationFlag;
                } else {
                  tempVehicle.certification.certificationDate =
                    response.certification.certificationDate;
                  tempVehicle.certification.forceCertificationFlag =
                    response.certification.forceCertificationFlag;
                }
              }
              return tempVehicle;
            } else {
              let tempVehicle = cloneDeep(rdr);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              if (!isNil(response.Code)) {
                tempVehicle.code = response.Code;
                tempVehicle.desc = response.desc;
                tempVehicle.deCertifyReason = response.deCertifyReason;
              }
              return tempVehicle;
            }
          } else {
            return false;
          }
        }));
    }
  }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public loadFIManager(dealerCd: string): Observable<any> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.staffMasterServicePath + '?dealerCd=' + dealerCd;
    // if (!isNil(rdr.dealerCd)) {
    //     path = path + '&dealerCd=' + rdr.dealerCd;
    // }
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      // UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicle.vin);
      // let vehicle = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //   observer.next(vehicle);
      //   observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
        .pipe(map((response: any) => {
          // this.logger.log(ViewConstants.VEHICLES_RESPONSE_RECEIVED_LOGGER + response);
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }))
    }
  }
}
