import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
    static mtdGridColumns = [
        {// regionName
            key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
            columnMinWidth: '185', columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealerName
            key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
            columnMinWidth: '200', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealer#
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
            columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        },
         {// TOTAL_
            key: ViewConstants.RDRS, fieldName: 'totSalesCount',
            style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
        },
        {// SAME_DAY_CERTS
            key: ViewConstants.SAME_DAY_CERTS,
            fieldName: 'sameDayCertCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// SDC%
            key: ViewConstants.SDC,
            fieldName: 'sameDayCertPercent', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.CONTAINS
        },
        {// CERT_AFTER_SALE
            key: ViewConstants.CERT_AFTER_SALE, fieldName: 'certAfterSaleCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// CAS
            key: ViewConstants.CAS, fieldName: 'certAfterSalePercent',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '170', columnMinWidth: '170',
            columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        },       
        {// totalPercent
            key: ViewConstants.TOTAL_PERCENT, fieldName: 'totalPercent',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        }
    ];
    static ytdGridColumns = [...this.mtdGridColumns];
    static customGridColumns = [...this.mtdGridColumns];
}
