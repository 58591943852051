<div fxLayout="column">
  <vehicle-certification-report-search-toolbar fxFlex="100" (filterDateEvent)="reportLoad($event)"
    [enteredvin]="enteredvin" [enteredDealerCode]="enteredDealerCode" [regionSelection]="regionSelection"
    [districtSelection]="districtSelection" [isDealerWiseFilterSelected]="true">
  </vehicle-certification-report-search-toolbar>

  <uc-region-dashboard-menubar-panel (clearAllButtonEvent)="clearAllFilters();"
    [showClearAllButton]="showClearAllButton" [totalInventoriesCount]="totalInventoriesCount" [showTotalRecords]="true"
    (exportPdfEvent)="downLoadPdf()" (exportExcelEvent)="downLoadXlsx()">
  </uc-region-dashboard-menubar-panel>

  <uc-delaer-wise-sales-cmp-grid [columnDefs]="columnDefs" [rows]="rows" (applySortEvent)="applyInventorySort($event)"
    (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort" (countIncreaseEvent)="countIncreaseEvent($event)"
    [totalSaleCount]="totalSaleCount"
    (countDecreaseEvent)="countDecreaseEvent($event)" (sendRegionSelection)="getRegionForFilter($event)">
  </uc-delaer-wise-sales-cmp-grid>
</div>