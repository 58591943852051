'use strict';

// Inspection Details
export interface Inspection {
  // to store inspection form string
  inspectionFormNumber?: string;
  // to store inspection approved date
  inspectionApprovedDate?: string;
}


export interface Districts {
  regionCode?: string;
  acqToSaleAvgMTD?: string;
  acqToSaleAvgYTD?: string;
  certToSaleAvgMTD?: string;
  certToSaleAvgYTD?: string;
  dailyGoldSales?: string;
  dailySilverSales?: string;
  daySupply?: string;
  districtCode?: string;
  invLessThan60Days?: string;
  monthlyGoldSales?: string;
  monthlySilverSales?: string;
  prevYrSameDaySales?: string;
  prevYrSameMonthSales?: string;
  prevYrTotalSales?: string;
  turnRate?: string;
  yearlyGoldSales?: string;
  yearlySilverSales?: string;
}
