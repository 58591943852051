import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { UCInventorySummary } from '../../../services/model/InventorySummary';
import { ViewConstants } from '../../../shared/shared.constants';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import * as models from '../../../services/model/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { cloneDeep, isNil, sortBy } from 'lodash';



import * as messageActions from '../../../actions/message-data.action';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';

@Component({
  selector: 'uc-regional-and-district-sales-cmp-grid',
  templateUrl: 'regional-disrtict-wise-sales-cmp-report-grid.component.html',
  styleUrls: ['./regional-disrtict-wise-sales-cmp-report-grid.component.scss']
})

/***
 * This component is used to the show the inventories in the inventory summar page
 */
export class UCRegionalAndDistrictWiseSalesCMPReportGridComponent implements OnInit{
  // Variable for grid column definitions
  @Input() columnDefs: any[] = [];
  @Input() columnDefsTotal: any[] = [];
  @Input() totalSaleCount: number;
  @Input() totalGridSaleCount: number;
  // private variable to hold the row datas
  _rows: Array<UCInventorySummary>;
  // Variable for sort columns list
  public sortColumnsList: Array<any> = [];
  regionSelection: string;
  districtSelection: string;
  dealerCodes:any[] = [];
  // Event to emit on row select/unselect
  @Output() onClickedEvent = new EventEmitter<any>();
  // Event to emit when vin is clicked in the grid
  @Output() navigateToVehicleDetailEvent = new EventEmitter<any>();
  // Event to emit when vin is clicked in the grid
  @Output() countIncreaseEvent = new EventEmitter<any>();
  @Output() sendRegionSelection = new EventEmitter<any>();

  // Event to emit when vin is clicked in the grid
  @Output() countDecreaseEvent = new EventEmitter<any>();
  @Input() dealerTotal: any;
  // to clear the current sorting for all columns
  @Input('clearSort')
  set clearSort(val: boolean) {
    if (val) {
      this.sortColumnsList = [];
    }
  }

  @ViewChild('inventoryTable',  {static: false}) inventoryTable: DatatableComponent;

  // Input variable to store the rows
  @Input('rows')
  get rows(): Array<UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<UCInventorySummary>) {
    this._rows = value;
  }

  // Event to emit during sorting the datas
  @Output() applySortEvent = new EventEmitter<any>();
  // Event to apply the filters
  @Output() applyFilterEvent = new EventEmitter<any>();

  reportType: string;
  fromDate: string;
  toDate: string;

  constructor(
    public sharedFunctionService: SharedFunctionService, 
     router: Router,
     private store: Store<fromRoot.AppState>,
    public sharedDataService: SharedDataService) {
    }
    
    ngOnInit(): void {
      
      this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
    this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';

    console.log('dealerTotal', this.dealerTotal);
    

    
  }
  
  regionSelectionEvent(event:any){
    this.sendRegionSelection.emit({region: event.region});    
  }


  /**
   * Method to apply the sorting in the grid
   */
  applySort(columnName: string) {
    let sortOrder = ViewConstants.ASC;
    if (this.sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
      sortOrder = this.sortColumnsList.find(x => x.columnName === columnName)
        .sortOrder === ViewConstants.ASC ? ViewConstants.DESC : ViewConstants.ASC;
    }
    this.sortColumnsList = [];
    this.sortColumnsList.push({ columnName: columnName, sortOrder: sortOrder });
    this.applySortEvent.emit({ columnName: columnName, sortOrder: sortOrder });
  }

  applyFilter(event: any, columnName: string, filterType: models.FilterType) {
    if (!this.inventoryTable.offset)
      this.inventoryTable.offset = 0;
    let filter: models.Filter = {};
    filter.columnname = columnName;
    filter.filterValue = event.target.value;
    let array = String(event.target.value).split(' ');
    filter.filterType = filterType;
    console.log("filter", filter);
    
    this.applyFilterEvent.emit(filter);
  }

  /**
   * Grid Row Select/Unselect Event
   */
  onClicked(checkboxChange: any, row: any) {
    let data = {
      checkBoxChecked: checkboxChange.source.checked,
      row: row
    };
    this.onClickedEvent.emit(data);
  }

  navigateToVehicleDetailScreen(event: any) {
    let selectedVehicleRoutes: models.VehicleRoute = {};
    selectedVehicleRoutes.vin = event;
    this.sharedDataService.setSelectedVehicle(selectedVehicleRoutes);
    this.navigateToVehicleDetailEvent.emit(event);
    // this.router.navigate(['/vehicle/' + event + '/' + this.sharedDataService.dealerCode]);
  }

  countPlus() {
    this.countIncreaseEvent.emit();
  }

  countMinus() {
    this.countDecreaseEvent.emit();
  }
}


