import {
  Component, Input, OnInit, Output, ViewEncapsulation,
  EventEmitter, ViewChild, ElementRef
} from '@angular/core';

import { Observable } from 'rxjs';
import * as viewModels from '../../services/view-model/view-models';
import * as models from '../../services/model/models';
import { isNil, cloneDeep, isEmpty } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as detailActions from '../../actions/vehicle-detail.action';
import { ValidationTypes } from '../../services/model/ValidatorTypes';
import { FormControl, FormControlDirective, FormControlName, Validators } from '@angular/forms';
import { SharedDataService } from '../../services/common/shared-data.service';
import * as dealerActions from '../../actions/dealer-detail.action';
import { ReportData } from '../../shared/master-data/reportData';

@Component({
  selector: 'uc-report',
  templateUrl: 'report.component.html',
  styleUrls: ['./report.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/***
 * This component is used to the show the Vehicle Detail screen
 */
export class ReportComponent implements OnInit {

  selectedInventoryReport: string = '';
  selectedSalesReport: string = '';
  selectedAccountingReport: string = '';
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<viewModels.DealerDetailView>;

  accountingReportsCopy: any;
  salesReportsCopy: any;
  inventoryReportsCopy: any;

  /**
   * Constructor method to create new instance of VehicleDetailComponent
   */
  constructor(public router: Router,
    private store: Store<fromRoot.AppState>, private el: ElementRef,
    public sharedDataService: SharedDataService) {
    if (isNil(this.sharedDataService.dealerData)) {
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    }
  }


  /**
   * Init method to initialize state of VehicleDetailDetailComponent
   */
  ngOnInit() {
    this.salesReportsCopy =
      ReportData.salesReports.filter(t => t.makeCode === this.sharedDataService.brandName);
    this.accountingReportsCopy =
      ReportData.accountingReports.filter(t => t.makeCode === this.sharedDataService.brandName);
    this.inventoryReportsCopy =
      ReportData.inventoryReports.filter(t => t.makeCode === this.sharedDataService.brandName);

    if (isNil(this.sharedDataService.dealerData)) {
      this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
      this.dealerDetailsData$.subscribe(data => {
        if (!isNil(data) && data !== undefined) {
          if (!isNil(data.allDealers) && data.allDealers.length > 0) {
            this.sharedDataService.dealerData = cloneDeep(data.allDealers);
          }
        }
      });
    }
  }

  proceedtoReportScreen() {
    // deletedCertificationReport
    if (this.selectedInventoryReport === '3') {
      this.router.navigate(['/vehicleForceCertificationReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName =
          'TCUV CERTIFICATION REPORT (INTERNAL ASSOCIATES)';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS PREOWNED CERTIFICATION REPORT (INTERNAL ASSOCIATES)';
    } else if (this.selectedInventoryReport === '1') {
      this.router.navigate(['/deletedCertificationReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName =
          'TOYOTA DELETED CERTIFICATION REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS DELETED CERTIFICATION REPORT';
    } else if (this.selectedInventoryReport === '2') {
      this.router.navigate(['/deletedCertificationSummaryReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName =
          'TOYOTA DELETED CERTIFICATION SUMMARY REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS DELETED CERTIFICATION SUMMARY REPORT';
    } else if (this.selectedInventoryReport === '4') {
      this.router.navigate(['/vehicleCertificationReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName = 'TCUV CERTIFICATION REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS CERTIFICATION REPORT';
    } else if (this.selectedInventoryReport === '5') {
      this.router.navigate(['/inventoryExceptionReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName =
          'TOYOTA INVENTORY EXCEPTION REPORT FOR CERTIFIED UNITS';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS INVENTORY EXCEPTION REPORT FOR CERTIFIED UNITS';
    } else if (this.selectedAccountingReport === '6' ||
      this.selectedAccountingReport === '7') {
      this.router.navigate(['/regionalModelLineRDR/6']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName = 'TCUV NATIONAL MODELWISE RDR REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS PREOWNED NATIONAL MODELWISE RDR REPORT';
    } else if (this.selectedAccountingReport === '8') {
      this.router.navigate(['/regionalModelLineRDR/8m']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName = 'TCUV REGIONAL MODELLINE RDR REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS PREOWNED REGIONAL MODELLINE RDR REPORT';
    } else if (this.selectedAccountingReport === '9') {
      this.router.navigate(['/regionalModelLineRDR/9']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName = 'TCUV REGIONAL MODELLINE RDR REPORT BY DEALER';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS PREOWNED REGIONAL MODELLINE RDR REPORT BY DEALER';
    } else if (this.selectedSalesReport === '11' || this.selectedSalesReport === '12') {
      this.router.navigate(['/salesComparisionModelYearReport/']);
      this.sharedDataService.currentMenuBarName =
        'LCPO SALES COMPARISON REPORT BY MODEL SERIES-MODEL YEAR';
    } else if (this.selectedSalesReport === '13') {
      this.router.navigate(['/lexusPreownedDailySalesReport/']);
      this.sharedDataService.currentMenuBarName =
        'LEXUS DIVISION PRE OWNED DAILY SALES REPORT BY SERIES FOR EACH DEALER';
    } else if (this.selectedSalesReport === '14') {
      this.router.navigate(['/lexusDealerParticipationReport/']);
      this.sharedDataService.currentMenuBarName =
        'SUMMARY OF SALES & STOCK INFORMATION BY DEALERSHIP FOR EACH REGION FOR LEXUS DEALERS';
    } else if (this.selectedSalesReport === '10') {
      this.router.navigate(['/topTCUVDealers/m']);
      this.sharedDataService.currentMenuBarName =
        'MONTH TO MONTH TCUV SALES COMPARISON BETWEEN CURRENT AND PREVIOUS MONTH';
    } else if (this.selectedSalesReport === '10y') {
      this.router.navigate(['/topTCUVDealers/y']);
      this.sharedDataService.currentMenuBarName =
        'MONTH TO MONTH TCUV SALES COMPARISON BETWEEN CURRENT AND PREVIOUS MONTH';
    } else if (this.selectedAccountingReport === '20') {
      this.router.navigate(['/tracVehiclesRetailSalesSummary/m']);
      this.sharedDataService.currentMenuBarName =
        'TOYOTA CERTIFIED TRAC VEHICLES RETAIL SALES  SUMMARY';
    } else if (this.selectedAccountingReport === '16') {
      this.router.navigate(['/salesOver65k/m']);
      this.sharedDataService.currentMenuBarName =
        'TOYOTA TCUV SALES AND INVENTORY OVER 65K';
    } else if (this.selectedAccountingReport === '22') {
      this.router.navigate(['/tracRetailSalesReport/m']);
      this.sharedDataService.currentMenuBarName =
        'TOYOTA CERTIFIED TRAC VEHICLES RETAIL SALES';
    } else if (this.selectedSalesReport === '15') {
      this.router.navigate(['/lexusWithoutWholesales/']);
      this.sharedDataService.currentMenuBarName =
        'LEXUS PREOWNED DAILY SALES WITHOUT WHOLESALES';
    } else if (this.selectedSalesReport === '18') {
      this.router.navigate(['/salesConsultantRDRReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName = 'TCUV SALES CONSULTANT RDR REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS PREOWNED SALES CONSULTANT RDR REPORT';
    } else if (this.selectedSalesReport === '19') {
      this.router.navigate(['/salesConsultantRDRDetailReport/']);
      if (this.sharedDataService.brandName === 'TOYOTA')
        this.sharedDataService.currentMenuBarName = 'TCUV SALES CONSULTANT RDR DETAIL REPORT';
      else
        this.sharedDataService.currentMenuBarName =
          'LEXUS PREOWNED SALES CONSULTANT RDR DETAIL REPORT';
    } else if (this.selectedSalesReport === '24') {
      this.router.navigate(['/lexusDistrictSummary']);
      this.sharedDataService.currentMenuBarName =
        'SUMMARY OF USED CARS SALES AT DISTRICT LEVEL FOR LEXUS DIVISION';
    } 
    // else if (this.selectedSalesReport === '25') {
    //   this.router.navigate(['/dssrReport']);
    //   this.sharedDataService.currentMenuBarName =
    //     'TMS TCUV SALES AND STOCK SUMMARY REPORT';
    // }
     else if (this.selectedInventoryReport === '26') {
      this.router.navigate(['/dailyRecapReport']);
      this.sharedDataService.currentMenuBarName =
        'LEXUS PRE-OWNED VEHICLES DAILY RECAP REPORT';
    } else if (this.selectedInventoryReport === '28') {
      this.router.navigate(['/recallReport/']);
      this.sharedDataService.currentMenuBarName = 'RECALL REPORT';
    } else if (this.selectedInventoryReport === '29') {
      this.router.navigate(['/inventoryReport/']);
      this.sharedDataService.currentMenuBarName = 'INVENTORY REPORT';
    } else if (this.selectedSalesReport === '30') {
      this.router.navigate(['/avgSalesReport/']);
      this.sharedDataService.currentMenuBarName = 'AVERAGE SALES REPORT';
    } else if (this.selectedAccountingReport === '31') {
      this.router.navigate(['/rdrDataReport/']);
      this.sharedDataService.currentMenuBarName = 'RDR DATA REPORT';
    } else if (this.selectedSalesReport === '34') {
      this.router.navigate(['/dailyGoldSilverReport/']);
      this.sharedDataService.currentMenuBarName = 'Daily Sales Breakdown GOLD/SILVER Report';    
    } else if (this.selectedSalesReport === '35') {
      this.router.navigate(['/ytyTCUVSalesComparisonReport/']);
      this.sharedDataService.currentMenuBarName = 'YTY TCUV Sales Comparison Report';
    } else if (this.selectedSalesReport === '36') {
      this.router.navigate(['/sameDayCertReport/']);
      this.sharedDataService.currentMenuBarName = 'Same Day Cert Report';
    } else if (this.selectedSalesReport === '37') {
      this.router.navigate(['/regionalAndDistrictWiseSalesComparisionReport/']);
      // this.sharedDataService.currentMenuBarName = 'Regional And District Wise Sales Comparison Cert Report';
      this.sharedDataService.currentMenuBarName = 'TMS TCUV SALES AND STOCK SUMMARY REPORT';
    } else {
      this.router.navigate(['/inventoryReport/']);
      this.sharedDataService.currentMenuBarName =
        'TCUV CERTIFICATION REPORT (INTERNAL ASSOCIATES)';
    }
  }

  changeReportSelection(type: string) {
    if (type === 'A') {
      this.selectedInventoryReport = '';
      this.selectedSalesReport = '';
    } else if (type === 'I') {
      this.selectedAccountingReport = '';
      this.selectedSalesReport = '';
    } else if (type === 'S') {
      this.selectedInventoryReport = '';
      this.selectedAccountingReport = '';
    }
  }
}
