import { ApplicationRef, Injectable, ViewContainerRef } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild,
  Router, RouterStateSnapshot
} from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { isNil } from 'lodash';
import * as msal from 'msal';
import { timer } from 'rxjs';
import { DialogService } from '../../shared/components/dialog/dialog.service';
import { ScreenMessages } from '../../shared/master-data/messages';
import { ViewConstants } from '../../shared/shared.constants';
import { environment } from '../../tessconfig';
import { SharedDataService } from './shared-data.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  viewContainerRef: ViewContainerRef;
  navigationURL: string;
  private tokenParam: msal.AuthenticationParameters;
  constructor(
    private router: Router,
    private msalSvc: MsalService,
    public sharedDataService: SharedDataService,
    private dialogsService: DialogService,
    private broadcastService: BroadcastService,
    private appRef: ApplicationRef
  ) {
    this.viewContainerRef = appRef.components[0].instance.viewContainerRef;
    this.tokenParam = { scopes: [environment.msalConfig.auth.clientId] };
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isAuthenticated()) {
      if (!this.sharedDataService.isPageEdited) {
        if (this.isAuthorizedRole()) {
          return true;
        } else {
          this.router.navigate(['/unauthorized']);
        }
      } else {
        let temp: boolean;
        this.navigationURL = state.url;
        return this.dialogsService.open(ViewConstants.VEHICLE_DETAIL_SCREEN_NAME,
          ScreenMessages.DISCARD_CHANGES,
          ViewConstants.YES, ViewConstants.NO,
          ViewConstants.OK_CANCEL,
          this.viewContainerRef, this, this.discardChanges,
          this.cancelDiscard)
      }
    } else {
      try {
        this.msalSvc.loginRedirect();
        return false;
      } catch (err) {
        console.log('Error during Login', err);
      }
    }
  }

  discardChanges(event: any) {
    event.dialogRef.close(true);
    event.parent.sharedDataService.isPageEdited = false;
    event.parent.router.navigate([event.parent.navigationURL]);

  }

  cancelDiscard(event: any) {
    event.dialogRef.close(true);
    event.parent.sharedDataService.isPageEdited = true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  /**
   * To check authorized role
   */

  isAuthorizedRole(): boolean {
    let isAuthorized: boolean = false;
    let account = this.msalSvc.getAccount();
    const roles: any = account ? account.idToken?.roles : [];

    const availableRoles = [
      ViewConstants.UCM_APP_TOYOTA,
      ViewConstants.UCM_APP_LEXUS,
      ViewConstants.CVA_APP_READONLY_TOYOTA,
      ViewConstants.CVA_APP_READONLY_LEXUS,
      ViewConstants.CVA_APP_TOYOTA,
      ViewConstants.CVA_APP_LEXUS,
      ViewConstants.CVA_APP_SUPER_TOYOTA,
      ViewConstants.CVA_APP_SUPER_LEXUS,
      ViewConstants.CVA_APP_SUPERADMIN_TMEX,
      ViewConstants.CVA_APP_ADMIN_TMEX,
      ViewConstants.CVA_APP_READONLY_TMEX,
      ViewConstants.CVA_APP_SUPERADMIN_GST,
      ViewConstants.CVA_APP_SUPERADMIN_SET
    ]

    if(Array.isArray(roles) && availableRoles.find((aR)=> roles.includes(aR))){
      isAuthorized = true;
    }

    return isAuthorized;
  }

  isAuthenticated(): boolean {
    if (this.msalSvc.getAccount())
      return true;
    else
      return false;
  }
}
