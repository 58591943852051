<div class="additionalScripts"></div>
<uc-layout class="dd-screen-layout" #layout (onLogoutEvent)="logout()">
  <div class="uc-loading"
    *ngIf="sharedDataService.showLoader && sharedDataService.currentScreenName !== sharedDataService.viewConstants.DEALER_DETAILS_SCREEN_NAME">
    <img class="uc-loading-img" src="assets/icon/loader.svg" alt="Loading...">
  </div>
  <uc-layout-nav>
    <div toolbar-content fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
      <button fxFlex="9" *ngIf="userType === 'TOYOTA'" mat-button class="dd-menu" mat-icon-button>
        <img style="height:90%;width:90%" src="assets/TCUV2.png" alt="TCUV_Logo"></button>
      <button fxFlex="9" *ngIf="userType ==='LEXUS'" mat-button class="dd-menu" mat-icon-button>
        <img style="height:100%;border: 1px solid #dad3d3;" src="assets/Lcert.png" alt="Lcert_Logo"></button>

      <span class="toyotaColor" *ngIf="userType === 'TOYOTA'"
        fxFlex="{{isShowDealerSection?4.2:5}}">{{sharedDataService.applicationTitle}}</span>
      <span class="lexusColor" *ngIf="userType === 'LEXUS'"
        fxFlex="{{isShowDealerSection?4.2:5}}">{{sharedDataService.applicationTitle}}</span>

      <div fxLayout="row" fxFlex="{{isShowDealerSection?55:72}}">
        <div>
          <button mat-button target="_blank" (click)="navigateToInventorySummary()" [ngClass]="invSummaryClass">
            INVENTORY OVERVIEW
          </button>
        </div>
        <span style="margin: 3px">&#124;</span>
        <div>
          <button mat-button target="_blank" (click)="navigateToRDRSummary()" [ngClass]="rdrSummaryClass">
            RDR OVERVIEW
          </button>
        </div>
        <span style="margin: 3px">&#124;</span>
        <div>
          <button mat-button target="_blank" (click)="navigateToSalesSummary()" [ngClass]="salesSummaryClass">
            SALES OVERVIEW
          </button>
        </div>
        <span style="margin: 3px">&#124;</span>
        <div>
          <button mat-button [matMenuTriggerFor]="ucmenu" [ngClass]="adminClass">
            ADMIN
          </button>
          <br>
          <mat-menu #ucmenu="matMenu">
            <ng-template [ngIf]="this.sharedDataService.superAdminScreenAccess">
              <ng-template [ngIf]="!sharedDataService.isSETUser && !sharedDataService.isGSTUser">
                <button mat-menu-item (click)="navigateTovehicleDecertification()">
                  Vehicle Decertification
                </button>
                <button mat-menu-item (click)="navigateToDealerParticipant()">
                  Participant Dealer selection
                </button>
                <button mat-menu-item *ngIf="!this.sharedDataService.isTMEXUser" (click)="navigateToRoleDetailScreen()">
                  Role Configuration
                </button>
              </ng-template>
            </ng-template>
            <button mat-menu-item (click)="navigateToVehicleHistory()">Audit Trail</button>

            <ng-template [ngIf]="this.sharedDataService.superAdminScreenAccess">
                <button *ngIf="!sharedDataService.isSETUser && !sharedDataService.isGSTUser && !this.sharedDataService.isTMEXUser" mat-menu-item 
                  (click)="navigateToRegionObjective()">
                  Region Objective
                </button>
                <button mat-menu-item *ngIf="!this.sharedDataService.isTMEXUser"
                  (click)="navigateToDealerObjective()">
                  Dealer Objective
                </button>
            </ng-template>
            <ng-template [ngIf]="!this.sharedDataService.isTMEXUser">
              <button mat-menu-item (click)="navigateToSalesTrend()">
                Sales Trend
              </button>
              <button mat-menu-item 
                (click)="navigateToRegionDashboard()">Region Dashboard</button>
              <button mat-menu-item 
                (click)="navigateToAccountingSummary()">Accounting Information</button>
            </ng-template>
          </mat-menu>
        </div>
        <span style="margin: 3px">&#124;</span>
        <div>
          <button *ngIf="!this.sharedDataService.isTMEXUser" mat-button target="_blank"
            (click)="navigateToSaleReversalReport()" [ngClass]="invSummaryReportClass">REPORT</button>
          <button *ngIf="this.sharedDataService.isTMEXUser" mat-button [matMenuTriggerFor]="ucreportmenu"
            [ngClass]="invSummaryReportClass">
            REPORT
          </button>
          <br>
          <mat-menu #ucreportmenu="matMenu">
            <button mat-menu-item *ngIf="this.sharedDataService.superAdminScreenAccess"
              (click)="navigateToTmexSalesReport()">Monthly Sales Report</button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="isShowDealerSection" fxLayout="row" class="uc-header-dealer-details" fxFlex="19">
        <div fxFlex="100" fxLayout="column">
          <span style="margin-bottom:-8px">DEALER NAME&nbsp;:&nbsp; <span style="color:gray"
              [attr.title]="sharedDataService.dealerName">{{sharedDataService.dealerNameCopy}}</span></span>
          <span>DEALER CODE&nbsp;&nbsp;:&nbsp; <span style="color:gray">{{sharedDataService.dealerCode}}</span></span>
        </div>

        <!--<div fxFlex="40" fxLayout="column">
          <span>{{sharedDataService.dealerName}}</span>
          <span>{{sharedDataService.dealerCode}}</span>
        </div>-->
      </div>

      <span fxFlex="14"> <span style="color:gray;font-size:14px;text-transform:uppercase"
          [attr.title]="sharedDataService.userName">{{sharedDataService.userNameCopy}} &nbsp;&nbsp;<span fxFlex="14"
            style="font-size:14px">
            <span>&#124;</span>
            <a style="cursor:pointer;text-decoration:underline;text-transform:none" (click)="logout()">
              Logout</a>
            <span>&#124;</span>
          </span>
        </span>
      </span>
      <!--<mat-icon class="dd-edit-icons dd-anchor" [class.dealerEditIcon]="sharedDataService.dealerName===''" (click)="findDealer()" [class.isDealerSelected]="sharedDataService.dealerName!==undefined && sharedDataService.dealerName!==''">
        mode_edit </mat-icon>-->
    </div>

    <!-- <div toolbar-content-sub fxLayout="row" fxLayoutAlign="left center" fxFlex>
      <div>
        <button mat-button target="_blank" (click)="navigateToInventorySummary()" [ngClass]="invSummaryClass">
          INVENTORY OVERVIEW
        </button>
      </div>
      <span style="margin: 3px">&#124;</span>
      <div>
        <button mat-button target="_blank" (click)="navigateToRDRSummary()" [ngClass]="rdrSummaryClass">
          RDR OVERVIEW
        </button>
      </div>
      <span style="margin: 3px">&#124;</span>
      <div>
        <button mat-button target="_blank" (click)="navigateToSalesSummary()" [ngClass]="salesSummaryClass">
          SALES OVERVIEW
        </button>
      </div>
      <span *ngIf="this.sharedDataService.adminScreenAccess" style="margin: 3px">&#124;</span>
      <div *ngIf="this.sharedDataService.adminScreenAccess">
        <button mat-button [matMenuTriggerFor]="ucmenu" [ngClass]="adminClass">
          ADMIN
        </button>
        <br>
        <mat-menu #ucmenu="matMenu">
          <button mat-menu-item *ngIf="this.sharedDataService.adminScreenAccess"
            (click)="navigateTovehicleDecertification()">Vehicle Decertification</button>
          <button mat-menu-item *ngIf="this.sharedDataService.adminScreenAccess"
            (click)="navigateToDealerParticipant()">Participant Dealer selection</button>
          <button mat-menu-item *ngIf="this.sharedDataService.adminScreenAccess"
            (click)="navigateToRoleDetailScreen()">Role Configuration</button>
          <button mat-menu-item *ngIf="this.sharedDataService.adminScreenAccess"
            (click)="navigateToVehicleHistory()">Audit Trail</button>
        </mat-menu>
      </div>
      <span style="margin: 3px">&#124;</span>
    </div> -->

    <div id="pageContent" class="mat-content" fxFlex="100" fxLayout="column" mat-noscroll-xy>
      <div fxFlex="100" *ngIf="responseCode===1 || responseCode===2">
        <uc-message-display *ngFor="let message of messageData$ | async" [dismissOnTimeout]="0" [message]="message"
          (onCloseMessage)="onCloseMessage($event)"></uc-message-display>
      </div>
      <div fxFlex="100" *ngIf="responseCode===0">
        <uc-message-display *ngFor="let message of messageData$ | async" [dismissOnTimeout]="5000" [message]="message"
          (onCloseMessage)="onCloseMessage($event)"></uc-message-display>
      </div>
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </uc-layout-nav>
</uc-layout>